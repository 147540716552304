

import React from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Carsdata from '../common/Carsdata'
import Callus from '../common/Callus'
import QualityClients from '../common/QualityClients'
import OurFeatures from '../homeComponent/OurFeatures'
import ContactForm from '../forms/ContactForm'

export default function TermCondition() {
  return (
    <div>
        <Navbar/>
<div className="no-bottom no-top zebra" id="content">
    
            

            {/* <!-- section close --> */}

           <div>
           <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt=""
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Terms and Conditions</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div className="container">
            <div className="row g-custom-x">
              <div className="col-lg-12 mb-sm-30 text-center d-flex justify-content-center">
                {/* <ContactForm /> */}
                <div className="col-12 " >
            {/* <h2  className='mt10 text-center' style={{color:"var(--secondary-color)"}}>TERMS CONDITIONS</h2> */}
            <ul className='privacyPolicy'>
            <li className='text-start'>We provide a single license for the purchase of any product from UBERApps.</li>
            <li className='text-start'>The manufacturer’s rights of our products sold to you solely belong to UBERApps.</li>
            <li className='text-start '>Selling our products to anyone without our written permission is illegal under PCT and raises a legal dispute.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start '><p>If you would like to modify the features of the solution you purchased, you can request your development team to do so. But if any code leaks or bug occurrence is reported because of the changes done by your team, Uberpps can not be held responsible for the problem.</p></li>
            <li className='text-start'>The maintenance charges are for maintaining white-labeled applications hosted on our servers, including periodic updates.</li>
            <li className='text-start'><b>Payment terms:</b><br/>
                              a. The kick-off amount is non-refundable.<br/>
                              b. 18% GST is included in the cost mentioned above (in the case of Indian clients)</li>


                              <li className='text-start'><b> Renewal in case of plan expiry:</b><br/>
                              a. Post plan expiry, the standard cost will be applicable as per the choice made by the client among the then available plans.<br/>
</li>

                             

            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
         </ul>
            </div>

              </div>

              <div className="col-lg-4">



              </div>
            </div>
          </div>
        </section>


{/* <section className="text-light jarallax">
                <img src="images/background/2.jpg" className="jarallax-img" alt=""/>
                <div className="container text-dark" style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
          <div className="row align-items-center" style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -15px' }}>
          <div className="text-center bg-light  mt100 rounded"  style={{ width: '100%', textAlign: 'center' }}>
            <h2  className='mt10' style={{color:"var(--secondary-color)"}}>TERMS AND CONDITIONS</h2>
            <ul>
            <li className='text-start'>We provide a single license for the purchase of any product from UBERApps.</li>
            <li className='text-start'>The manufacturer’s rights of our products sold to you solely belong to UBERApps.</li>
            <li className='text-start'>Selling our products to anyone without our written permission is illegal under PCT and raises a legal dispute.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start'>If you would like to modify the features of the solution you purchased, you can request your development team to do so. But if any code leaks or bug occurrence is reported because of the changes done by your team, Uberpps can not be held responsible for the problem.</li>
            <li className='text-start'>The maintenance charges are for maintaining white-labeled applications hosted on our servers, including periodic updates.</li>
            <li className='text-start'><b>Payment terms:</b><br/>
                              a. The kick-off amount is non-refundable.<br/>
                              b. 18% GST is included in the cost mentioned above (in the case of Indian clients)</li>


                              <li className='text-start'><b> Renewal in case of plan expiry:</b><br/>
                              a. Post plan expiry, the standard cost will be applicable as per the choice made by the client among the then available plans.<br/>
</li>

                             

            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
         </ul>
            </div>

          </div>
        </div>
            </section> */}
            
    </div>


          {/* <QualityClients/> */}

           <Callus/>
			
        </div>
         <Footer/>
    </div>
  )
}
