import React, { useEffect, useState } from 'react'
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { useLocation } from 'react-router-dom';


const Route = () => {
  const history = useLocation();

  useEffect(() => {
      window.scroll(0, 0);
  }, [history]);

  const auth = sessionStorage.getItem('authuser')

     const [login, setLogin] = useState(false)

  useEffect(()=>{
    console.log(auth,"auth....");
    if(auth){
      setLogin(true)
    }
  
  },[sessionStorage.getItem('authuser')])
  const isLoggedIn = auth;



    return login ? <LoginRoutes />:<MainRoutes /> 
  }

export default Route



