import React from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import ProfileForm from '../forms/ProfileForm'

export default function Profile() {
    const userInfoString = sessionStorage.getItem("authuser");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;


    const handleLogout = () => {
        sessionStorage.clear();
      };
      
  return (
    <div>
        <Navbar/>
<div className="no-bottom no-top zebra" id="content">
            <div id="top"></div>
            
            {/* <!-- section begin --> */}
            <section id="subheader" className="jarallax text-light">
                <img src="images/background/14.jpg" className="jarallax-img" alt=""/>
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
									<h1>My Profile</h1>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </section>
            {/* <!-- section close --> */}

            <section id="section-settings" className="bg-gray-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb30">
                            <div className="card p-4 rounded-5">
                                <div className="profile_avatar">
                                    <div className="profile_img">
                                        <img 
                                                src={userInfo?.profile_path || "images/profile/1.jpg"}
                                        // src={"images/profile/1.jpg" }
                                        alt=""/>
                                    </div>
                                    <div className="profile_name">
                                        <h4>
                                           { userInfo.fname   }                                            
                                            <span className="profile_username text-gray">{userInfo?.email}</span>
                                        </h4>
                                    </div>
                                </div>
                                <div className="spacer-20"></div>
                                <ul className="menu-col">
                                    <li><a href="dashboard"><i className="fa fa-home"></i>Dashboard</a></li>
                                    <li><a href="account-profile" className="active"><i className="fa fa-user"></i>My Profile</a></li>
                                    <li><a href="account-booking"><i className="fa fa-calendar"></i>My Trips</a></li>
                                    {/* <li><a href="account-favorite"><i className="fa fa-car"></i>My Favorite Cars</a></li> */}
                                    {/* <li><a href='/login' onClick={handleLogout}><i className="fa fa-sign-out" ></i>Sign Out</a></li> */}


                                    
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-9">
                            <div className="card p-4  rounded-5">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ProfileForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			
			
        </div>
<Footer/>
    </div>
  )
}
