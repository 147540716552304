import React from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import ContactForm from "../forms/ContactForm";
import { OFFICE_ADDRESS } from "../utils/Constant";

export default function Contact() {


  return (
    <div>
      <Navbar />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        {/* <!-- section begin --> */}
        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt=""
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Contact Us</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section close --> */}

        <section aria-label="section">
          <div className="container">
            <div className="row g-custom-x">
              <div className="col-lg-8 mb-sm-30">
                <ContactForm />
              </div>

              <div className="col-lg-4">


     {Object.keys(OFFICE_ADDRESS).map((officeKey) => {
        const office = OFFICE_ADDRESS[officeKey];
        return (
          <div className="de-box mb30" key={officeKey}>
            <h4>{officeKey} Office</h4>
            <address className="s1">
              <span>
                <i className="fa fa-map-marker fa-lg"></i>
                {office.address}
              </span>
              <span>
                <i className="fa fa-phone fa-lg"></i>
                {office.phone}
              </span>
              <span>
              <i className="fa fa-whatsapp fa-lg"></i>
                {office.whatsapp}
              </span>

              
              <span>
                <i className="fa fa-envelope-o fa-lg"></i>
                <a href={`mailto:${office.email}`}>{office.email}</a>
              </span>
            </address>
          </div>
        );
      })}



              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}
