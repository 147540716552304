import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../common/LoadingSpinner";
import moment from "moment";

export default function AccountBooking() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const userInfoString = sessionStorage.getItem("authuser");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const apikey = userInfo?.key || null;
  const userId = userInfo?.userid || null;

  const [orders, setOrders] = useState([]);
  const [groupedTrips, setGroupedTrips] = useState([]);
  const [status, setStatus] = useState();

  // const orders = [];
  // ----------------------------------------common fetch function ------------------------------
  function fetchHandler(url, data) {
    // setIsLoading(true);
    return fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // setIsLoading(false);
        return result;
      });
  }
  const getStatusBadgeClass = (status) => {
    if (status === "completed") {
      return "bg-success";
    } else if (
      status === "cancel" ||
      status === "paid_cancel" ||
      status === "expired" ||
      status === "p_cancel_drop" ||
      status === "p_cancel_pickup"
    ) {
      return "bg-danger";
    } else if (
      status === "request" ||
      status === "assigned" ||
      status === "accept" ||
      status === "begin" ||
      status === "arrive"
    ) {
      return "bg-warning";
    } else {
      return "bg-warning";
    }
  };

  const getTripStatus = (status) => {
    switch (status) {
      case "request":
        return "Request";
      case "assigned":
        return "Assigned";
      case "accept":
        return "Accepted";
      case "arrive":
        return "Arrived";
      case "begin":
        return "Begin";
      case "completed":
        return "Completed";
      case "cancel":
        return "Cancelled";
      case "p_cancel_pickup":
        return "Partner Cancel at Pickup Location";
      case "p_cancel_drop":
        return "Partner Cancel at Drop Location";
      case "expired":
        return "Expired";
      case "paid_cancel":
        return "Cancelled";
      default:
        return "Unknown Status";
    }
  };

  // ---------------------------------------------get trip list----------------------------------------------/
  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_URL}/tripapi/gettrips?api_key=${apikey}&user_id=${userId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setOrders(data.response);
        setIsLoading(false);

        const groupTrips = data?.response?.reduce((acc, trip) => {
          const status = trip.trip_status;
          acc[status] = acc[status] || [];
          acc[status].push(trip);
          return acc;
        }, {});
        setGroupedTrips(groupTrips);

        console.log("Grouped Trips:", groupedTrips);
      });
  }, []);

  // ---------------------------------------------------------gmt to local conversion----------------------------------------
  useEffect(() => {
    // Check if groupedTrips is defined
    console.log(groupedTrips, "groupedTrips.............");
    if (groupedTrips != null && groupedTrips != undefined) {
      Object.keys(groupedTrips).forEach((status) => {
        // Use map to create a new array with updated trip_date values
        const updatedTrips = groupedTrips[status].map((trip) => {
          console.log(trip.trip_date, "trip.trip_date.........");
          const localTime = moment
            .utc(trip.trip_date, "YYYY-MM-DD HH:mm:ss")
            .local();
          const updatedDate = localTime.format("YYYY-MM-DD hh:mm a");
          console.log(updatedDate, "updated date.........");
          // Return a new object with the updated_date property
          return { ...trip, updated_date: updatedDate };
        });
        // Update the groupedTrips object with the new array of updated trips
        groupedTrips[status] = updatedTrips;
        console.log(groupedTrips[status]);
        setOrders(groupedTrips[status]);
      });
      // The groupedTrips object is now updated with the new trip_date values
      console.log(groupedTrips);
    }
  }, [groupedTrips]);

  const handleCancelTrip = (tripId) => {
    let tripCancelReason = prompt("Reason For Cancel");

    let data = {
      trip_id: tripId,
      trip_status: "cancel",
      is_return_details: "1",
      trip_reason: tripCancelReason || "",
    };
    fetch(`${process.env.REACT_APP_URL}/tripapi/updatetrip?api_key=${apikey}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res.json().then((data) => {
        if (res.status == 200) {
          alert("Trip Cancelled");
          navigate("/dashboard");

          // setopenmodal(false);
          // setmsg(true);
          // setreason("");
          // historytable();
        }
      });
    });
  };

  const handleLogout = () => {
    sessionStorage.clear();
    // setTimeout(()=>{
    //   navigate("/login");
    //   window.location.reload()
    // },1000)
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}

      <Navbar />
      <div className="no-bottom no-top zebra" id="content">
        <div id="top"></div>

        {/* <!-- section begin --> */}
        <section id="subheader" className="jarallax text-light">
          <img src="images/background/14.jpg" className="jarallax-img" alt="" />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1> My Trips</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section close --> */}

        <section id="section-settings" className="bg-gray-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb30">
                <div className="card p-4 rounded-5">
                  <div className="profile_avatar">
                    <div className="profile_img">
                      <img
                        src={userInfo?.profile_path || "images/profile/1.jpg"}
                        alt=""
                      />
                    </div>
                    <div className="profile_name">
                      <h4>
                        {userInfo.fname}
                        <span className="profile_username text-gray">
                          {userInfo?.email}
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div className="spacer-20"></div>
                  <ul className="menu-col">
                    <li>
                      <a href="/dashboard">
                        <i className="fa fa-home"></i>Dashboard
                      </a>
                    </li>
                    <li>
                      <a href="/account-profile">
                        <i className="fa fa-user"></i>My Profile
                      </a>
                    </li>
                    <li>
                      <a href="" className="active">
                        <i className="fa fa-calendar"></i>My Trips
                      </a>
                    </li>
                    {/* <li><a href="/account-favorite"><i className="fa fa-car"></i>My Favorite Cars</a></li> */}
                    {/* <li><a href='/login' onClick={handleLogout}><i className="fa fa-sign-out" ></i>Sign Out</a></li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-9">
                {!groupedTrips && (
                  <h1 className="text-center mt100">No Trip Available!</h1>
                )}
                
                {groupedTrips &&
                  Object.keys(groupedTrips)?.map((status) => (
                    // <div key={status}>
                    <div key={status} className="card p-4 rounded-5 mb25">
                      <h4>
                        {getTripStatus(status)}
                        {/* {status == "cancel" ? "Cancelled" : status}  */}
                      </h4>
                      <table className="table de-table ">
                        <thead>
                          <tr>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-dark">
                                Trip ID
                              </span>
                            </th>
                            <th scope="col" className="px-4">
                              <span className="text-uppercase fs-12 text-dark ">
                                Pick Up Date
                              </span>
                            </th>
                            {/* <th scope="col" className="text-center">
                          <span className="text-uppercase fs-12 text-gray">
                            Car Name
                          </span>
                        </th> */}
                            <th scope="col" className="text-center px-20">
                              <span className="text-uppercase fs-12 text-dark">
                                Location
                              </span>
                            </th>
                            {/* <th scope="col">
                          <span className="text-uppercase fs-12 text-gray">
                            Drop Off Location
                          </span>
                        </th> */}

                            <th scope="col" className="text-center">
                              <span className="text-uppercase fs-12 text-dark">
                                Fare
                              </span>
                            </th>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-dark">
                                Status
                              </span>
                            </th>
                          </tr>
                        </thead>
                        {/* <tbody>
              {groupedTrips[status].map(trip => (
                <tr key={trip.trip_id}>
                  <td>{trip.trip_id}</td>
                </tr>
              ))}
            </tbody> */}
                        <tbody>
                          {groupedTrips[status].map((trip, index) => (
                            <tr key={trip.trip_id}>
                              <td className="col-1">
                                <span className="d-lg-none d-sm-block">
                                  Order ID
                                </span>
                                <div className="badge bg-gray-100 text-dark">
                                  #{trip.trip_id}
                                </div>
                              </td>
                              <td className="col-lg-2 col-md-2 px-4">
                                <span className="d-lg-none d-sm-block">
                                  Pick Up Date
                                </span>
                                {/* {trip.trip_date} */}
                                {trip.updated_date}
                              </td>
                              {/* <td className="col-1 text-center">
                     <span className="d-lg-none d-sm-block ">
                       Car Name
                     </span>
                     <span className="bold">{trip.cat_name}</span>
                   </td> */}
                              <td className="ml20 col-7 ">
                                <span className="d-lg-none d-sm-block ">
                                  Location
                                </span>
                                <div className="ml20">
                                  <span style={{ color: "darkgreen" }}>
                                    {" "}
                                    Pickup{" "}
                                  </span>
                                  {trip.actual_from_loc? trip.actual_from_loc :trip.trip_from_loc}
                                </div>
                                {trip.trip_to_loc && (
                                  <div className="ml20 ">
                                    {" "}
                                    <span className="text-danger">
                                      {" "}
                                      DropOff{" "}
                                    </span>
                                    {trip.actual_to_loc? trip.actual_to_loc :trip.trip_to_loc}
                                  </div>
                                )}
                                {trip.trip_reason && (
                                  <div className="ml20 ">
                                    {" "}
                                    <span className="text-warning">
                                      {" "}
                                      Reason{" "}
                                    </span>
                                    {trip.trip_reason}
                                  </div>
                                )}
                              </td>
                              {/* <td>
                     <span className="d-lg-none d-sm-block">
                       Drop Off Location
                     </span>
                     {trip.trip_to_loc}
                   </td> */}

                              <td className=" col-2 text-center">
                                <span className="d-lg-none d-sm-block ">
                                  Fare
                                </span>
                                {process.env.REACT_APP_CURRENCY}
                                {trip.trip_pay_amount}
                              </td>
                              <td className="borbottom text-center">
                                <div
                                  className={`badge rounded-pill ${getStatusBadgeClass(
                                    trip.trip_status
                                  )}`}
                                >
                                  {/* {trip.trip_status == "cancel" ? "Cancelled" : trip.trip_status} */}

                                  {getTripStatus(trip.trip_status)}
                                </div>

                                {/* {trip.trip_reason &&  <p className="lh-1">   {trip.trip_reason}</p>} */}
                                {trip.trip_status == "request" && (
                                  <div
                                    className="bg-danger   badge rounded-pill "
                                    onClick={() => {
                                      handleCancelTrip(trip.trip_id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Cancel
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}

                {/* <div className="card p-4 rounded-5 mb25">
                                <h4>Scheduled Orders</h4>

                                <table className="table de-table">
                                  <thead>
                                    <tr>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Order ID</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Car Name</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Pick Up Location</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Drop Off Location</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Pick Up Date</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Return Date</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Status</span></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01245</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">Ferrari Enzo</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>Kentucky</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Michigan</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 14, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 16, 2023</td>
                                      <td><div className="badge rounded-pill bg-warning">scheduled</div></td>
                                    </tr>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01245</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">VW Polo</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>Philadelphia</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Washington</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 16, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 18, 2023</td>
                                      <td><div className="badge rounded-pill bg-warning">scheduled</div></td>
                                    </tr>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01216</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">Toyota Rav 4</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>Baltimore</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Sacramento</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 19, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 20, 2023</td>
                                      <td><div className="badge rounded-pill bg-warning">scheduled</div></td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>

                            <div className="card p-4 rounded-5 mb25">
                                <h4>Completed Orders</h4>

                                <table className="table de-table">
                                  <thead>
                                    <tr>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Order ID</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Car Name</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Pick Up Location</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Drop Off Location</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Pick Up Date</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Return Date</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Status</span></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01236</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">Jeep Renegade</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>New York</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Los Angeles</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 2, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 11, 2023</td>
                                      <td><div className="badge rounded-pill bg-success">completed</div></td>
                                    </tr>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01287</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">Hyundai Staria</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>Nevada</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>New Mexico</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 6, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 12, 2023</td>
                                      <td><div className="badge rounded-pill bg-success">completed</div></td>
                                    </tr>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01236</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">Range Rover</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>Virginia</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Oregon</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 2, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 13, 2023</td>
                                      <td><div className="badge rounded-pill bg-success">completed</div></td>
                                    </tr>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01287</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">BMW M2</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>Kansas City</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Houston</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 1, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 14, 2023</td>
                                      <td><div className="badge rounded-pill bg-success">completed</div></td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>

                            <div className="card p-4 rounded-5 mb25">
                                <h4>Cancelled Orders</h4>

                                <table className="table de-table">
                                  <thead>
                                    <tr>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Order ID</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Car Name</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Pick Up Location</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Drop Off Location</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Pick Up Date</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Return Date</span></th>
                                      <th scope="col"><span className="text-uppercase fs-12 text-gray">Status</span></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01263</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">Mini Cooper</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>San Fransisco</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Chicago</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 8, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 12, 2023</td>
                                      <td><div className="badge rounded-pill bg-danger">cancelled</div></td>
                                    </tr>
                                    <tr>
                                      <td><span className="d-lg-none d-sm-block">Order ID</span><div className="badge bg-gray-100 text-dark">#01263</div></td>
                                      <td><span className="d-lg-none d-sm-block">Car Name</span><span className="bold">Ford Raptor</span></td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Location</span>Georgia</td>
                                      <td><span className="d-lg-none d-sm-block">Drop Off Location</span>Lousiana</td>
                                      <td><span className="d-lg-none d-sm-block">Pick Up Date</span>March 8, 2023</td>
                                      <td><span className="d-lg-none d-sm-block">Return Date</span>March 13, 2023</td>
                                      <td><div className="badge rounded-pill bg-danger">cancelled</div></td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}
