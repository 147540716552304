import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

export default function ProfileForm() {
  const userInfoString = sessionStorage.getItem("authuser");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const apikey = userInfo?.key || null;
  const userId = userInfo?.userid || null;

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    updated_fname: userInfo?.fname,
    updated_lname: userInfo?.lname,
  });

  // ----------------------------------------common fetch function ------------------------------
  function fetchHandler(url, data) {
    // setIsLoading(true);
    return fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      return res.json().then((result) => {
        // setIsLoading(false);
        return result;
      });
    });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target.value, "event.target...........");
    setFormData({
      ...formData,
      updated_fname: event.target.value,
      // updated_lname:
    });
  };
  const handleLnameChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target.value, "event.target...........");
    setFormData({
      ...formData,
      updated_lname: event.target.value,
      // updated_lname:
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("hiiii....");
    console.log("formData.........", formData);

    const form = event.currentTarget;
    // if (form.checkValidity() === false) {
      if(true){
      event.preventDefault();
      // event.stopPropagation();
      async function fetchData() {
        let url = `${process.env.REACT_APP_URL}/userapi/updateuserprofile?api_key=${apikey}&user_id=${userId}`;
        let data = {
          u_fname: formData.updated_fname,
          u_lname: formData.updated_lname,
          user_id: userId,
          is_return_details: "1",
        };
        const result = await fetchHandler(url, data);

        if (result?.code == 200) {
          console.log(result, "result........");
          toast.success("Updated Successfully");
          console.log(userInfo);
          
          userInfo.fname = formData.updated_fname;
          
          // userInfo.fname = result.response.u_fname;
          userInfo.lname = result.response.u_lname;
          userInfo.name = result.response.u_name;

          console.log(userInfo);
          let updatedUInfo = userInfo;

          let updatedUserInfo = JSON.stringify(updatedUInfo);
          sessionStorage.setItem("authuser", updatedUserInfo);
        setTimeout(()=>{
          window.location.reload();
        },3000)
        }
      }
      if (
        userInfo?.fname != formData.updated_fname ||
        userInfo?.lname != formData.updated_lname
      ) {


        fetchData();
      }
      else{
        toast.error("Looks like you didn't make any update.")
      }
    }

    setValidated(true);
  };

  return (
    <>
     <ToastContainer />    
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="form-create-item"
        className="form-border"
        // method="post"
        // action="email.php"
      >
        <div className="de_tab tab_simple ">
          <ul className="de_nav">
            <li className="active">
              <span>Profile</span>
            </li>
            <li className="d-none">
              <span>Notifications</span>
            </li>
          </ul>
          <div className="de_tab_content">
            <div className="tab-1">
              <div className="row">
                <div className="col-lg-6 mb20">
                  <Form.Group controlId="updated_fname">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="updated_fname"
                      placeholder="Enter your first name"
                      minLength="2"
                      maxLength="30"
                      // pattern="[A-Za-z]+"
                      defaultValue={userInfo?.fname}
                      onChange={handleInputChange}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      Please provide a valid first name.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb20">
                  <Form.Group controlId="updated_lname">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="updated_lname"
                      placeholder="Enter your last name"
                      // minLength="2"
                      maxLength="30"
                      // pattern="[A-Za-z]+"
                      defaultValue={userInfo?.lname}
                      onChange={handleLnameChange}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      Please provide a valid last name.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb20">
                  <Form.Group controlId="userPhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="user_phone"
                      placeholder="Enter your phone number"
                      required
                      pattern="[0-9]{10}"
                      defaultValue={`${userInfo?.countryid} ${userInfo?.phone}`}
                      readOnly
                    />

                    <Form.Control.Feedback type="invalid">
                      Please provide a valid phone number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb20">
                  <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="email_address"
                      placeholder="Enter email"
                      required
                      pattern="^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$"
                      defaultValue={userInfo?.email}
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                {/* <div className="col-lg-6 mb20">
                  <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="Enter username"
                      required
                      pattern="[A-Za-z0-9]+"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid username.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div> */}

                {/* <div className="col-lg-6 mb20">
                  <Form.Group controlId="userPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="user_password"
                      placeholder="********"
                      required
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid password.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div> */}

                {/* <div className="col-lg-6 mb20">
                  <Form.Group controlId="userPasswordReEnter">
                    <Form.Label>Re-enter Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="user_password_re-enter"
                      placeholder="********"
                      required
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid password.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div> */}

                {/* <div className="col-md-6 mb20">
                  <Form.Group controlId="selectLanguage">
                    <Form.Label>Language</Form.Label>
                    <p className="p-info">Select your preferred language.</p>
                    <Form.Control as="select">
                      <option>English</option>
                      <option>French</option>
                      <option>German</option>
                      <option>Japanese</option>
                      <option>Italian</option>
                    </Form.Control>
                  </Form.Group>
                </div> */}
                {/* <div className="col-md-6 mb20">
                  <Form.Group controlId="selectHourFormat">
                    <Form.Label>Hour Format</Form.Label>
                    <p className="p-info">Select your preferred hour format.</p>
                    <Form.Control as="select">
                      <option>24-hour</option>
                      <option>12-hour</option>
                    </Form.Control>
                  </Form.Group>
                </div> */}
              </div>
            </div>

            {/* notification section begins*/}

            {/* <div className="tab-2">
              <div className="row">
                <div className="col-md-6 mb-sm-20">
                  <div className="switch-with-title s2">
                    <h5>Discount Notifications</h5>
                    <div className="de-switch">
                      <Form.Check
                        type="checkbox"
                        label=""
                        id="notif-item-sold"
                        className="checkbox"
                      />
                    </div>
                    <div className="clearfix"></div>
                    <p className="p-info">
                      You'll get notification while new discount available.
                    </p>
                  </div>

                  <div className="spacer-20"></div>

                  <div className="switch-with-title s2">
                    <h5>New Product Notification</h5>
                    <div className="de-switch">
                      <Form.Check
                        type="checkbox"
                        label=""
                        id="notif-bid-activity"
                        className="checkbox"
                      />
                    </div>
                    <div className="clearfix"></div>
                    <p className="p-info">
                      You'll get notification while new product available.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="switch-with-title s2">
                    <h5>Daily Reports</h5>
                    <div className="de-switch">
                      <Form.Check
                        type="checkbox"
                        label=""
                        id="notif-auction-expiration"
                        className="checkbox"
                      />
                    </div>
                    <div className="clearfix"></div>
                    <p className="p-info">
                      We will send you a report everyday.
                    </p>
                  </div>

                  <div className="spacer-20"></div>

                  <div className="switch-with-title s2">
                    <h5>Monthly Reports</h5>
                    <div className="de-switch">
                      <Form.Check
                        type="checkbox"
                        label=""
                        id="notif-outbid"
                        className="checkbox"
                      />
                    </div>
                    <div className="clearfix"></div>
                    <p className="p-info">
                      We will send you a report each month.
                    </p>
                  </div>
                </div>

                <div className="spacer-20"></div>
              </div>
            </div> */}
          </div>
        </div>

        <Button type="submit" id="submit" className="btn-main">
          Update profile
        </Button>
      </Form>
    </>
  );
}
