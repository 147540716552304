import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faRoad, faTag, faMapPin } from '@fortawesome/free-solid-svg-icons';
export default function OurFeatures() {
  return (
    <div>
      <section aria-label="section">
        <div className="container" style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
          <div className="row align-items-center" style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -15px' }}>
          <div className="text-center" style={{ width: '100%', textAlign: 'center' }}>
            <h2>Our Features</h2>
            <p style={{ marginBottom: '20px' }}>Dolor esse sint officia est voluptate et qui deserunt et est eiusmod cillum mollit sunt nulla cillum sit ut culpa ullamco.</p>
            </div>

            <div className="col-lg-3 col-md-12"
            //  style={{ width: '25%', padding: '0 15px' }}
            
            >
              <div className="box-icon s2 p-small mb20" style={{ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '20px', animationName: 'fadeInRight', animationDuration: '1s' }}>
                <FontAwesomeIcon icon={faTrophy} style={{ backgroundColor: 'var(--primary-color)', color: 'white', padding: '8px', borderRadius: '50%' }} />
                <div className="d-inner" style={{ paddingLeft: '10px' }}>
                  <h4>First class services</h4>
                  Est dolore ut laboris eu enim eu veniam nostrud esse laborum duis consequat nostrud id
                </div>
              </div>
              <div className="box-icon s2 p-small mb20" style={{ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '20px', animationName: 'fadeInL', animationDuration: '1s', animationDelay: '0.75s' }}>
                <FontAwesomeIcon icon={faRoad} style={{ backgroundColor: 'var(--primary-color)', color: 'white', padding: '8px', borderRadius: '50%' }} />
                <div className="d-inner" style={{ paddingLeft: '10px' }}>
                  <h4>24/7 road assistance</h4>
                  Est dolore ut laboris eu enim eu veniam nostrud esse laborum duis consequat nostrud id
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-9 mx-auto p-2" 
            // style={{ width: '50%', padding: '0 15px' }}
            >
              <img src="images/misc/car-2.png" alt="" className="img-fluid" style={{ maxWidth: '100%', height: 'auto', animationName: 'fadeInUp', animationDuration: '1s' }} />
            </div>

            <div className="col-lg-3 col-md-12" 
            // style={{ width: '25%', padding: '0 15px' }}
            >
              <div className="box-icon s2 d-invert p-small mb20" style={{ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '20px', animationName: 'fadeInL', animationDuration: '1s', animationDelay: '1s' }}>
                <FontAwesomeIcon icon={faTag} style={{ backgroundColor: 'var(--primary-color)', color: 'white', padding: '8px', borderRadius: '50%' }} />
                <div className="d-inner" style={{ paddingLeft: '10px' }}>
                  <h4>Quality at Minimum Expense</h4>
                  Est dolore ut laboris eu enim eu veniam nostrud esse laborum duis consequat nostrud id
                </div>
              </div>
              <div className="box-icon s2 d-invert p-small mb20" style={{ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '20px', animationName: 'fadeInL', animationDuration: '1s', animationDelay: '1.25s' }}>
                <FontAwesomeIcon icon={faMapPin} style={{ backgroundColor: 'var(--primary-color)', color: 'white', padding: '8px', borderRadius: '50%' }} />
                <div className="d-inner" style={{ paddingLeft: '10px' }}>
                  <h4>Free Pick-Up & Drop-Off</h4>
                  Est dolore ut laboris eu enim eu veniam nostrud esse laborum duis consequat nostrud id
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
