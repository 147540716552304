import { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../common/LoadingSpinner";

export default function RegisterForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [country, setcountry] = useState([]);
  let [formData, setFormData] = useState({
    city_id: process.env.REACT_APP_CITY_ID,
  });

  async function fetchHandler(url, data) {
    setIsLoading(true);
    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setIsLoading(false);
        return result;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/commonapi/getcountries`,
      // "https://rentals.stitchmyapp.com/ver5.1/webservices/1.1.3/index.php/constantapi/getcountries"
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setcountry(result.response);
      });
  }, []);

  const handleCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
    handleInputChange(event);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleKeyPress = (e) => {
    const isNumeric = /^[0-9]$/;
    if (!isNumeric.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("formData", formData);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let data = formData;
      let url = `${process.env.REACT_APP_URL}/userapi/registration?`;

      async function fetchData() {
        const result = await fetchHandler(url, data);
        if (result?.code == 200) {
          formData = {};
          setFormData({
            city_id: process.env.REACT_APP_CITY_ID,
          });
          toast.success(process.env.REACT_APP_REGISTER_SUCCESS_MSG);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          console.log(result.message);
          toast.error("This email or phone no.is already registered");
        }
      }

      fetchData();
    }

    setValidated(true);
  };
  console.log("---------------- regiseter form");

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="link-Hover"></div>
      <h3>Don't have an account? Register now.</h3>
      <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo.
      </p>
      <ToastContainer />
      <div className="spacer-10"></div>

      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        name="contactForm"
        id="contact_form"
        className="form-border"
        action="/userapi/registration"
        method="post"
        // method="post"
        // action=""
      >
        <Row>
          <Col md={6}>
            <Form.Group controlId="u_fname">
              <Form.Label className="labelSpacing">Name:</Form.Label>
              <Form.Control
                type="text"
                name="u_fname"
                placeholder="Name"
                required
                pattern="^(?!\s+$)[A-Za-z\s]+$"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="u_email">
              <Form.Label className="labelSpacing">Email Address:</Form.Label>
              <Form.Control
                type="text"
                name="u_email"
                placeholder="Email"
                required
                pattern="^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* <Col md={6}>
            <Form.Group controlId="u_name">
              <Form.Label>Choose a Username:</Form.Label>
              <Form.Control
                type="text"
                name="u_name"
                placeholder="Username"
                required
                pattern="[A-Za-z0-9]+"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid username.
              </Form.Control.Feedback>
            </Form.Group>
          </Col> */}
          <Col md={6}>
            <Form.Group controlId="c_code">
              <Form.Label className="labelSpacing">Country Code:</Form.Label>
              <Form.Control
                as="select"
                name="c_code"
                required
                onChange={handleCountryCode}
                value={selectedCountryCode}
              >
                <option value="">Select Country Code</option>
                {country?.map((country, index) => (
                  <option key={index} value={country.code}>
                    {`${country.code}  (${country.name}) `}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a country code.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="u_phone">
              <Form.Label className="labelSpacing">Phone:</Form.Label>
              <Form.Control
                type="text"
                name="u_phone"
                placeholder="Phone"
                required
                onKeyPress={handleKeyPress}
                pattern="^(?!([0-9])\1+$)[0-9]{10}$"
                maxLength="10"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide your 10-digit phone number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="u_password" className="mb40">
              <Form.Label className="labelSpacing">Password:</Form.Label>
              <Form.Control
                type="password"
                name="u_password"
                placeholder="Password"
                required
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ lineHeight: "20px" }}
              >
                {/* Please provide a valid password. */}

                {/* Password should contain at least 8 char, 1uppercase, <br/> one lowercase, one digit, and one special char             */}
                <p className="col-lg-7 col-md-4 col-sm-8 ">
                  Password must contain 1 number and 1 uppercase and lowercase
                  letter, and at least 8 or more characters
                </p>
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/*   re-enter password -----------------
          
          <Col md={6}>
            <Form.Group controlId="rePassword">
              <Form.Label>Re-enter Password:</Form.Label>
              <Form.Control
                type="password"
                name="rePassword"
                placeholder="Re-enter Password"
                required
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </Form.Group>
          </Col> */}

          <Col md={6}>
            <Form.Group controlId="ref_id">
              <Form.Label className="labelSpacing">Referral ID:</Form.Label>
              <Form.Control
                type="text"
                name="ref_id"
                placeholder="Referral ID"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>

          {/* <div className="spacer-10"></div> */}
          <div className="col-md-12  col-lg-6">
            <div id="submit" className="pull-left labelSpacing">
              <Button
                type="submit"
                id="send_message"
                className="btn-main color-2"
              >
                Register Now
              </Button>
            </div>

            <div id="mail_success" className="success">
              Your message has been sent successfully.
            </div>
            <div id="mail_fail" className="error">
              Sorry, an error occurred while sending your message.
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="text-lg-end text-sm-start  mt-2 small font-weight-bold col-lg-6 link-Hover">
            Already have an account?{" "}
            <Link to="/login" className="link-dark">
              Sign in
            </Link>
          </div>
        </Row>
      </Form>
    </>
  );
}
