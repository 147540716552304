import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

export default function ContactForm() {
    const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});




  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyPress = (e) => {
    const isNumeric = /^[0-9]$/;
    if (!isNumeric.test(e.key)) {
      e.preventDefault();
    }
  };
   
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        console.log(event.currentTarget);
        console.log(validated,"validated.....");
        console.log(form.checkValidity());
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        setValidated(true);
        }
        else{
            console.log("formData:",formData);
            alert("Thanks for filling out our form!")
            // event.preventDefault();
            
        }
    };

    return (
        <>
            <h3>Do you have any question?</h3>

            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                name="contactForm"
                id="contact_form"
                className="form-border"
                // method="post"
                // action="#"
            >
                <Form.Group className="mb-3" controlId="u_name" style={{}}>
                    <Form.Control
                        name='u_name'
                        type="text"
                        placeholder="Your Name"
                        required
                        pattern="^(?!\s+$)[A-Za-z\s]+$"
                        onChange={handleInputChange}

                    />
                    <Form.Control.Feedback type="invalid" >
                        Please provide your name.
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="spacer-10"></div>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Control
                        name='email'
                        type="text"
                        placeholder="Your Email"
                        required
                        pattern="^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$"
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="spacer-10"></div>
                <Form.Group className="mb-3" controlId="phone">
                    <Form.Control
                        name='phone'
                        type="text"
                        onKeyPress={handleKeyPress}
                        placeholder="Your Phone"
                        pattern="^(?!([0-9])\1+$)[0-9]{10}$"
                        required
                        onChange={handleInputChange}
                        maxLength="10" 
                        
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid phone number.
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="spacer-10"></div>
                <Form.Group className="mb-3" controlId="message">
                    <Form.Control
                        name='message'
                        as="textarea"
                        rows={4}
                        placeholder="Your Message"
                        required
                        onChange={handleInputChange}

                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your message.
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="spacer-10"></div>
                <div className="g-recaptcha" data-sitekey="copy-your-site-key-here"></div>

                <Button variant="primary" type="submit" className="btn-main">
                    Send Message
                </Button>

                <div id="success_message" className="success">
                    Your message has been sent successfully. Refresh this page if you want to send more messages.
                </div>
                <div id="error_message" className="error">
                    Sorry, there was an error sending your form.
                </div>
            </Form>
        </>
    );
}
