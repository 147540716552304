import React,{ useEffect }  from "react"
import "./App.css";

import Route from "./Route/Route";

function App() {
  

  return (
    <div className="App">

      <Route />
    </div>
  );
}

export default App;

// <BrowserRouter>
//       <Routes>
//         <Route  path='/' element={< Home />} />
//         {/* <Route path="/register" element={<Register />} />
//         <Route path="/booking" element={<Booking />} />
//         <Route path="/dashboard" element={<AcountDashboard />} />
//         <Route path="/account-booking" element={<AccountBooking />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/account-profile" element={<Profile />} />
//           <Route path="*" element={<Error />} /> */}
//         <Routess/>
//       </Routes>
//     </BrowserRouter>
