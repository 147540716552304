import React,{useState} from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import RegisterForm from '../forms/RegisterForm'
import LoadingSpinner from '../common/LoadingSpinner';

export default function Register() {
  return (
 <div>
  {/* <div id="de-preloader"></div> */}

  <Navbar/>

    <div className="no-bottom no-top" id="content">
    <div id="top"></div>
    
    {/* <!-- section begin --> */}
        <section id="subheader" className="jarallax text-light">
            <img src="images/background/subheader.jpg" className="jarallax-img" alt=""/>
                <div className="center-y relative text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>Register</h1>

                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
        </section>

        <section aria-label="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">

                         <RegisterForm />


                    </div>
                </div>
            </div>
        </section>
    {/* <!-- section close --> */}
    </div>

    <Footer/>

</div>
  )
}
