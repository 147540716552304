import React from 'react'

export default function AnyQuestions() {
  return (
    <div>
<section id="section-faq">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <h2>Have Any Questions?</h2>
                            <div className="spacer-20"></div>
                        </div>
                    </div>
                    <div className="row g-custom-x">
                        <div className="col-md-6 wow fadeInUp">
                            <div className="accordion secondary">
                                <div className="accordion-section">
                                    <div className="accordion-section-title" data-tab="#accordion-1">
                                        How do I get started with Tips &amp; Trick?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-1">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-2">
                                        What is difference for each plan?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-2">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-3">
                                        What kind of Tips &amp; Trick do I need?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-3">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 wow fadeInUp">
                            <div className="accordion secondary">
                                <div className="accordion-section">
                                    <div className="accordion-section-title" data-tab="#accordion-b-4">
                                        Why do I need domain name?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-4">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-b-5">
                                        What my website protected from hackers?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-5">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-b-6">
                                        How do I backup my website?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-6">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    </div>
  )
}
