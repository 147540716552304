



import React from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Carsdata from '../common/Carsdata'
import Callus from '../common/Callus'
import QualityClients from '../common/QualityClients'
import OurFeatures from '../homeComponent/OurFeatures'

export default function PrivacyPolicy() {
  return (
    <div>
        <Navbar/>
<div className="no-bottom no-top zebra" id="content">
<section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt=""
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Privacy Policy</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
            

            {/* <!-- section close --> */}

           <div>
           <section aria-label="section">
          <div className="container">
            <div className="row g-custom-x">
            <div className="container text-dark" style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
          <div className="row align-items-center" style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -15px' }}>
          <div className="text-center rounded"  style={{ width: '100%', textAlign: 'center' }}>
            {/* <h2  style={{color:"var(--secondary-color)"}}>PRIVACY POLICY</h2> */}
            <ul>
              <li className='text-start'><b>What information do we collect?</b><br/>
            We collect information from you when you register on our site, subscribe to our newsletter or fill out a form.
            <br/>When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address or phone number. You may, however, visit our site anonymously. 
            </li>


            <li className='text-start'><b>1. Profile Picture:</b><br/>
            We collect and display the profile picture you upload within the App for user identification and enhanced user experience.
            </li>

                              <li className='text-start'><b>2. Document Verification:</b><br/>
                              In certain cases, we may require you to submit images of documents for verification purposes. We use these images solely for verifying your identity or fulfilling legal/regulatory requirements.<br/>
                              We take reasonable measures to protect your images from unauthorised access, alteration, or destruction.<br/>
                          </li>

                          <li className='text-start'><b>What do we use your information for?</b><br/>
                          Any of the information we collect from you may be used in one of the following ways:
            </li>
                             

            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
            <li className='text-start'>The client will be held responsible for the changes in the source code done by their own development team and UBERApps will not fix those issues or errors.</li>
         </ul>
            </div>

          </div>
        </div>

              <div className="col-lg-4">



              </div>
            </div>
          </div>
        </section>


            
    </div>


          {/* <QualityClients/> */}

           <Callus/>
			
        </div>
         <Footer/>
    </div>
  )
}
