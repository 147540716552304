export const OFFICE_ADDRESS = {
    "INDIA": {
      "address": "E-30, 3rd Floor, Sector 3, Noida, Uttar Pradesh - 201301",
      "phone": "+91-886-021-3347",
      "email": "talk@uberapps.tech",
      "whatsapp":"+91-8860213347"
    },
    "UK": {
      "address": "08 W 36th St, New York, NY 10001",
      "phone": "+1 333 9296",
      "email": "contact@example.com",
      "whatsapp":"+1 678 5656"

      
    },
  };