import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { setHours, setMinutes, addMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import LoadingSpinner from "../common/LoadingSpinner";
import { useNavigate } from "react-router-dom";

export default function BookingForm() {
  const navigate = useNavigate();
  const userInfoString = sessionStorage.getItem("authuser");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const apikey = userInfo?.key || null;
  const userId = userInfo?.userid || null;

  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let [formData, setFormData] = useState({});
  let [selectedVehicle, setSelectedVehicle] = useState(null);
  const [VehicleList, setVehicleList] = useState();

  const [address, setaddress] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const autocompleteRef = useRef(null);
  const dropAutocompleteRef = useRef(null);
  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });
  const [coord, setcoord] = useState({
    lat: "",
    lng: "",
  });

  const [dropoffAddress, setDropoffAddress] = useState("");
  const [dropoffAddressValue, setDropoffAddressValue] = useState("");
  const [isDropoffAddressValid, setIsDropoffAddressValid] = useState(true);
  const [calculateDistance, setCalculateDistance] = useState("");
  const [calculateDuration, setCalculateDuration] = useState("");

  // const [selectedDate, setSelectedDate] = useState(new Date());
  // selectedDate.setMinutes(selectedDate.getMinutes() + 15);

  const defaultTime = new Date();
  defaultTime.setMinutes(defaultTime.getMinutes() + 15);
  const [selectedDate, setSelectedDate] = useState(defaultTime);
  const [GMTtime, setGMTtime] = useState(
    moment.utc(defaultTime).format("YYYY-MM-DD HH:mm:ss")
  );

  const [estimPriceList, setEstimPriceList] = useState(null);
  const [estData, setEstData] = useState({});

  const [baseFare, setBaseFare] = useState(null);
  const [farePerKm, setFarePerKm] = useState(null);
  const [farePerMin, setFarePerMin] = useState(null);
  const [serviceTax, setServiceTax] = useState(null);
  const [totalFare, setTotalFare] = useState(null);

  const [rOTP,setROTP]   = useState(null)

  // ----------------------------------------common fetch function ------------------------------
  async function fetchHandler(url, data) {
    // setIsLoading(true);
    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // setIsLoading(false);
        return result;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  // --------------------------------------fetch vehicle List------------------------------------------
  useEffect(() => {
    let url = `${process.env.REACT_APP_URL}/categoryapi/getcategories`;
    let data = {
      // api_key: apikey,
      city_id: process.env.REACT_APP_CITY_ID,
    };

    async function fetchData() {
      const result = await fetchHandler(url, data);

      if (result?.code == 200) {
        const newData = [...result.response];
        newData.sort((a, b) => parseInt(a.sort_order) - parseInt(b.sort_order));
        setVehicleList(newData);
      }
      //  else {
      //   alert("Please refresh your page");
      // }
    }
    fetchData();
  }, []);

  // --------------------------------------------------select vehicle function--------------------------------
  const handleVehicleType = (val) => {
    setSelectedVehicle(val);

    setBaseFare(val?.cat_base_price);
    setFarePerKm(val?.cat_fare_per_km);
    setFarePerMin(val?.cat_fare_per_min);
    setFormData({
      ...formData,
      cat_name: val?.cat_name,
      category_id: val?.category_id,
      trip_base_fare: val?.cat_base_price,
    });
  };
  useEffect(() => {
    let defaultVehicle = VehicleList?.[0];
    handleVehicleType(defaultVehicle);
  }, [VehicleList]);

  // ---------------------------------------handle pickup Location------------------------------

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);

    console.log(l1, "li...........");
    setcoordinate(l1);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
  };

  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
    setServiceTax(0);
    setTotalFare(0);
    setCalculateDistance(0);
    setCalculateDuration(0);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      console.log(autocompleteRef)

      if (
        autocompleteRef?.current &&
        !autocompleteRef?.current?.event?.target?.value &&
        !addressValue
      ) {
        setaddress("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [address]);

  // const handlepickupInputBlur = () => {
  //   if (addressValue == " " && coordinate==" " && address!="") {
  //     console.log("hii");
  //     setPickupAddress(null);
  //     setAddressValue("");
  //     setaddress("");
  //   }
  //   onChangeAddress("");
  // };
  // // ---------------------------------------fetch distance duration------------------------------------------
  useEffect(() => {
    async function fetchData() {
      if (address == dropoffAddress) {
        toast.error("Please select different dropoff location");
        return;
      }

      let url = `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`;
      let data = "";
      const result = await fetchHandler(url, data);

      if (result?.status == "OK") {
        // console.log(result?.routes[0].legs[0].distance.text, "result.2.......");
        setCalculateDistance(result?.routes[0]?.legs[0]?.distance.text);
        setCalculateDuration(result?.routes[0]?.legs[0]?.duration.text);
      }
    }
    if (address && dropoffAddress) {
      fetchData();
    }
  }, [coordinate, coord]);

  // --------------------------------------------fetch Estimate price-----------------------------------------
  useEffect(() => {
    async function fetchEstimatePrice() {
      let url = `${process.env.REACT_APP_URL}/tripapi/estimatetripfare`;
      let data = {
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_scheduled_drop_lat: coord.lat,
        trip_scheduled_drop_lng: coord.lng,
        trip_distance: calculateDistance,
        trip_hrs: calculateDuration,
        trip_from_loc: address,
        trip_to_loc: dropoffAddress,
        trip_date: GMTtime,
        api_key: apikey,
        city_id: process.env.REACT_APP_CITY_ID,
        user_id: userId,
        seats: 1,
        is_share: 1,
      };
      setIsLoading(true);

      const result = await fetchHandler(url, data);
      setIsLoading(false);

      if (result?.code == 200) {
        setEstimPriceList(result.response);
        console.log(result.response, "result.3.......");
      }
    }
    if (calculateDistance && calculateDuration) {
      fetchEstimatePrice();
    }
  }, [calculateDistance, calculateDuration]);

  useEffect(() => {
    if (estimPriceList) {
      let catId = selectedVehicle.category_id;
      setServiceTax(estimPriceList[catId]?.tax_amt);
      setTotalFare(estimPriceList[catId]?.trip_pay_amount);
      setEstData(estimPriceList[catId]);
    }
  }, [selectedVehicle, estimPriceList]);

  // --------------------------------------handle dropoff location--------------------------------------------
  const handleChangeDropoff = (newValue) => {
    setDropoffAddress(newValue);
    setIsDropoffAddressValid(newValue.trim() !== "");
  };

  const handleSelectDropoff = (address, placeId) => {
    setDropoffAddress(address);
    setDropoffAddressValue(address);
    setIsDropoffAddressValid(true);
    handleDrop(address);
  };

  const handleClearSelectionDropoff = () => {
    setDropoffAddress("");
    setDropoffAddressValue("");
    setIsDropoffAddressValid(true);
    setServiceTax(0);
    setTotalFare(0);
    setCalculateDistance(0);
    setCalculateDuration(0);
  };

  const handleDrop = async (value) => {
    const result = await geocodeByAddress(value);
    // setdroplatlng(result[0].formatted_address);
    const l2 = await getLatLng(result[0]);
    console.log(l2, "l2........");
    setcoord(l2);
  };


  useEffect(() => {
    const handleDropOutsideClick = (event) => {
      console.log(dropAutocompleteRef)
      if (
        dropAutocompleteRef?.current &&
        !dropAutocompleteRef?.current?.event?.target?.value &&
        !dropoffAddressValue
      ) {
        setDropoffAddress("");
      }
    };

    document.addEventListener("mousedown", handleDropOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleDropOutsideClick);
    };
  }, [dropoffAddress, dropoffAddressValue]);


  // const handleDropInputBlur = () => {
  //   if (dropoffAddressValue == "") {
  //     console.log("hii");
   
  //     setDropoffAddress("");
  //   setDropoffAddressValue("");
  //   }
  //   handleClearSelectionDropoff("");
  // };

  // ----------------------------------------handle date Time----------------------------------------------
  const handleDateAndTime = (date) => {
    const gmtTime = moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
    setGMTtime(gmtTime);

    setSelectedDate(date);
  };

  const handleSubmit = (event) => {
    console.log("formData", formData);

    event.preventDefault();

    async function fetchData() {
      // let url = `${process.env.REACT_APP_URL}/tripapi/save`;
      let url = `${process.env.REACT_APP_URL}/tripapi/save?api_key=${apikey}`;
      let data = {
        // api_key: apikey,
        trip_date: GMTtime,
        // cat_name: formData.cat_name,
        trip_from_loc: address,
        trip_to_loc: dropoffAddress,
        city_id: process.env.REACT_APP_CITY_ID,
        user_id: userId,
        category_id: formData.category_id,
        trip_currency: process.env.REACT_APP_CURRENCY,
        trip_base_fare: formData.trip_base_fare,
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_scheduled_drop_lat: coord.lat,
        trip_scheduled_drop_lng: coord.lng,
        trip_distance: calculateDistance,
        trip_total_time: calculateDuration,
        trip_pay_amount: totalFare,
        tax_amt: serviceTax,
        trip_dunit: "km",
        // trip_type: "normal",
        seats: "1",
        trip_status: "request",
        is_share: "0",
        is_delivery: "0",
        est_data: estData,
        otp:OTP,
      };
      console.log(data, "data");
      const result = await fetchHandler(url, data);
      setIsLoading(false);

      if (result?.code == 200) {
        // alert("hii")
        toast.success("Your request has been saved successfully,");
        setaddress(" ");
        setAddressValue("");
        setDropoffAddress(" ");
        setDropoffAddressValue("");
        handleDateAndTime(defaultTime);
        setServiceTax(0);
        setTotalFare(0);
        setCalculateDistance(0);
        setCalculateDuration(0);
        console.log(result, "result.......");
        setTimeout(() => {
          navigate("/dashboard");
        }, 4000);
      } else {
        toast.error(result?.message);
      }
    }
    if (address && dropoffAddress && totalFare) {
      setIsLoading(true);
      var OTP = Math.floor(1000 + Math.random() * 9000);
      const jsonString = JSON.stringify({ otp: OTP });

      // Set the OTP in sessionStorage
      sessionStorage.setItem("otp", jsonString);
      
      fetchData();
    }

    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.stopPropagation();
    // }

    setValidated(true);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <ToastContainer />

      <Form
        name="contactForm"
        id="contact_form"
        // action="/userapi/bookinjg"
        // method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div id="step-1" className="row mb30  ">

       <div className="col-lg-9  "> 

          <div
            style={{ background: "white", }}
            className="de-box  "
          >
            <div className="row">
              <div className="col-lg-5 ">
                {/* ------------------------------------------------Select vehicle type--------------------------------------------- */}
                <h5>What is your vehicle type? </h5>
                <div
                  className="de_form de_radio g-0 bookingPage"
                  style={{
                    // overflowX: "auto",
                    // display: "flex",
                    justifyContent:"center",
                    flexWrap:"wrap",
                    paddingBottom: "15px",
                  }}
                >
                  {VehicleList?.map((val, i) => (
                    <div key={i} className="radio-img col-lg-3 col-sm-4 col-5 ">
                      <Form.Check
                        type="radio"
                        name="cat_name"
                        id={val.category_id}
                        value={val.cat_name}
                        label={
                          <div className="VehicleText">
                            <img src={val.cat_icon_path} alt="" s />
                            {val.cat_name}
                          </div>
                        }
                        onChange={(event) => {
                          handleVehicleType(val);
                        }}
                        defaultChecked={i === 0}
                      />
                    </div>
                  ))}
                </div>

                <div />

                {/* ----------------------------------------select locations --------------------------------- */}
              </div>

              <div className="col-lg-7">
                <div className="row">
                  {/* ----------------------------------------select pickup location --------------------------------- */}
                  <div className="col-lg-6 mb20">
                    <Form.Group>
                      <h5>Pick Up Location</h5>

                      <PlacesAutocomplete
                        value={address}
                        onChange={onChangeAddress}
                        onSelect={handleSelect}
                        ref={autocompleteRef}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="autoCommpleteInput">
                            {/* <input {...getInputProps({ placeholder: 'Enter Your Pickup Location' })} style={{    width:"90%", border: "0px solid"}}/> */}

                            <Form.Control
                              {...getInputProps({
                                placeholder: "Enter Your Pickup Location",
                              })}
                              style={{ width: "100%",paddingRight:"25px" }}
                              required
                              // onBlur={() => {
                              //   handlepickupInputBlur();
                              // }}
                            />
                            {addressValue && (
                              <span
                                className="crossBtn"
                                onClick={handleClearSelection}
                              >
                                <b>X</b>
                              </span>
                            )}

                            <div className=" bg-light   autocomplete-dropdown-container  dropdown-width">
                              {loading && <div>Loading...</div>}
                              {
                                suggestions.map((suggestion) => (
                                  <div
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion)}
                                    className="dropDownBorder"
                                  >
                                    {suggestion.description}
                                  </div>
                                ))}
                            </div>
                            {/* <div> */}

                            <Form.Control.Feedback type="invalid" className="custom__invalid_err">
                              Please enter a valid pickup location.
                            </Form.Control.Feedback>
                            {/* </div> */}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Group>
                  </div>

                  {/* ----------------------------------------select drop location --------------------------------- */}
                  <div className="col-lg-6 mb20">
                    <Form.Group>
                      <h5>Drop Off Location</h5>
                      <PlacesAutocomplete
                        value={dropoffAddress}
                        onChange={handleChangeDropoff}
                        onSelect={handleSelectDropoff}
                        ref={dropAutocompleteRef}

                        // onBlur={()=>{setDropoffAddress(" ")}}
                        // onSelect={handleDrop}
                        // onBlur={() => {
                        //   handleDropInputBlur();
                        // }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="autoCommpleteInput">
                            <Form.Control
                              {...getInputProps({
                                placeholder: "Enter Your Drop Off Location",
                              })}
                              style={{ width: "100%", paddingRight: "25px" }}
                              required
                            />
                            {dropoffAddressValue && (
                              <span
                                className="crossBtn"
                                onClick={handleClearSelectionDropoff}
                              >
                                <b>X</b>
                              </span>
                            )}
                            <div className=" bg-light  
                             autocomplete-dropdown-container 
                            drop-dropdown-container-b">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  key={suggestion.id}
                                  {...getSuggestionItemProps(suggestion)}
                                  className="dropDownBorder"
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                            <Form.Control.Feedback type="invalid" className="custom__invalid_err">
                              Please enter a valid drop-off location.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Group>

                    <div className="jls-address-preview jls-address-preview--hidden">
                      <div className="jls-address-preview__header"></div>
                    </div>
                  </div>

                  {/* ---------------------------------------- pickup Date & Time --------------------------------- */}
                  <div className="col-lg-6 mb20 mt20">
                    <h5>Pick Up Date & Time</h5>
                    <div className="date-time-field bookingform">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateAndTime}
                        //    onChange={(e) => handleDateAndTime(e)}
                        // onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeIntervals={5}
                        minDate={new Date()}
                        minTime={
                          new Date(selectedDate).toDateString() ===
                          new Date().toDateString()
                            ? defaultTime
                            : null
                        }
                        maxTime={
                          new Date(selectedDate).toDateString() ===
                          new Date().toDateString()
                            ? new Date().setHours(23, 55)
                            : null
                        }
                        dateFormat="yyyy/MM/dd hh:mm aa"
                      />

                      <Form.Control.Feedback type="invalid">
                        Please select a time.
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  {/* <div className="col-lg-6 mb-3">
                    <h5>Return Date & Time</h5>
     
                  </div> */}
                </div>
                <div className="col-lg-12">
                  <Button
                    type="submit"
                    id="send_message"
                    className="btn-main pull-right"
                  >
                    Book now
                  </Button>
                </div>
              </div>
            </div>
          </div>
       </div>


          {/* --------------------------------------- Booking summary------------------------------- */}
          <div className="col-lg-3 col-md-12 col-sm-12"
            style={{ background: "white",}}
          >

          <div
            className="de-box text-dark"
          >
            <div className="">
              <h4 className="text-center">Booking Summary</h4>
              <div className="row ">
              <table className="my-table">
  <tbody>
    <tr>
  
    
    </tr>
    <tr>
      <th>Base Fare:</th>
      <td className="text-end">
        {process.env.REACT_APP_CURRENCY}
        {baseFare}
      </td>
    </tr>
    <tr>
      <th>Fare Per Km:</th>
      <td className="text-end">
        {process.env.REACT_APP_CURRENCY}
        {farePerKm}
      </td>
    </tr>
    <tr>
      <th>Fare Per Min:</th>
      <td className="text-end">
        {process.env.REACT_APP_CURRENCY}
        {farePerMin}
      </td>
    </tr>
    <tr>
      <th>Distance:</th>
      <td className="text-end">
        {address && dropoffAddressValue ? calculateDistance : 0}
      </td>
    </tr>
    <tr>
      <th>Duration:</th>
      <td className="text-end">
        {address && dropoffAddressValue ? calculateDuration : 0}
      </td>
    </tr>
    <tr>
      <th>Service Tax:</th>
      <td className="text-end">
        {process.env.REACT_APP_CURRENCY}
        {address && dropoffAddress ? serviceTax : 0}
      </td>
    </tr>
    <tr>
      <th>Total Fare:</th>
      <td className="text-end">
        {process.env.REACT_APP_CURRENCY}
        {address && dropoffAddress ? totalFare : 0}
      </td>
    </tr>
  </tbody>
</table>

                {/* <div className="col-sm-5 fs-6 text-start text-dark ">
                  <div>Base Fare:</div>
                  <div>Fare Per Km:</div>
                  <div>Fare Per Min:</div>
                  <div>Distance:</div>
                  <div>Duration:</div>
                  <div>Service Tax:</div>
                  <div>Total Fare:</div>
                </div>
                <div className="col-sm-7 text-end  fs-6">
                  <div>
                    {process.env.REACT_APP_CURRENCY}
                    {baseFare}
                  </div>
                  <div>
                    {process.env.REACT_APP_CURRENCY}
                    {farePerKm}
                  </div>
                  <div>
                    {process.env.REACT_APP_CURRENCY}
                    {farePerMin}
                  </div>
                  <div>
                    {address && dropoffAddressValue ? calculateDistance : 0}
                  </div>
                  <div>
                    {address && dropoffAddressValue ? calculateDuration : 0}
                  </div>
                  <div>
                    {process.env.REACT_APP_CURRENCY}
                    {address && dropoffAddress ? serviceTax : 0}
                  </div>
                  <div>
                    {process.env.REACT_APP_CURRENCY}
                    {address && dropoffAddress ? totalFare : 0}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
       </div>

      </Form>
    </>
  );
}
