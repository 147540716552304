// import React from 'react'

// const ForgotPswdForm = () => {
//   return (
//     <div>ForgotPswdForm</div>
//   )
// }

// export default ForgotPswdForm




import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../common/LoadingSpinner";


export default function  ForgotPswdForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});

  async function fetchHandler(url, data) {
    // setIsLoading(true);
    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setIsLoading(false);
        return result;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  // ----------------------------------------localization------------------------------
  // useEffect(() => {
  //   async function fetchData() {
  //     let url = `https://app.uberapps.tech/webservices/1.1.2/index.php/localisationapi/getlocalisations`;
  //     let data = "";

  //     const result = await fetchHandler(url, data);

  //     if (result?.code == 200) {
  //       console.log(result);
  //     }
  //   }

  //   // Call the async function
  //   setTimeout(() => {
  //     fetchData();
  //   }, [3000]);
  // }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("formData", formData);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let data = formData;
      let url = `${process.env.REACT_APP_URL}/userapi/forgetpassword?`;
      async function fetchData() {
        setIsLoading(true);

        const result = await fetchHandler(url, data);

        if (result?.code == 200) {
          console.log(result, "result...............");


          toast.success("Password updated successfully. Please check your mail")
          setTimeout(() => {
            navigate("/login");
          }, 3000);
         
          setIsLoading(false);
        } else {
          console.log(result.message);
          toast.error("Invalid email");
        }
      }

      // Call the async function
      fetchData();
    }
    setValidated(true);
  };
  return (
    <>
      {isLoading && <LoadingSpinner />}
      <h4>Forgot Password</h4>

      <ToastContainer style={{ marginTop: "100px" }} />
      <div className="spacer-10"></div>
      <Form
        noValidate
        id="form_register"
        className="form-border"
        validated={validated}
        onSubmit={handleSubmit}
        // action="/userapi/login"
        method="post"
      >
        <Form.Group controlId="u_email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            name="u_email"
            placeholder="Email your register email"
            required
            pattern="^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$"
            onChange={handleInputChange}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <div className="spacer-10"></div>

 
        {/* 
        <div className="spacer-10"></div>
        <div className="spacer-10"></div> */}
  

        <Button
          type="submit"
          id="send_message"
          className="btn-main btn-fullwidth rounded-3 mt-2"
        >
         send
        </Button>

        <div className="text-center mt-2 small font-weight-bold  link-Hover">
          Don't have an account?
          <Link to="/register" className="link-dark">
          {" "}  Register now.
          </Link>
        </div>
        <div className="text-center small   text-grey">
               or
        </div>

        <div className="text-center  small font-weight-bold  link-Hover">
        Back to
          <Link to="/login" className="link-dark ">
      {" "}  Login 
          </Link>
        </div>
        
      </Form>
    </>
  );
}

