import React,{useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Booking from "../component/Booking";
// import RegisterForm from '../forms/RegisterForm';
import AcountDashboard from "../component/AcountDashboard";
import Home from "../component/Home";
import Register from "../component/Register";
import Profile from "../component/Profile";
import About from "../component/About";
import Contact from "../component/Contact";
import Login from "../component/Login";
import AccountBooking from "../component/AccountBooking";
import Error from "../common/Error";
import { Navigate, useRoutes } from "react-router-dom";
import TermCondition from "../component/TermCondition";
import PrivacyPolicy from "../component/PrivacyPolicy";
import { ForgotPswd } from "../component/ForgotPswd";

const MainRoutes = () => {


  const auth = sessionStorage.getItem('authuser')

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/dashboard" element={<AcountDashboard />} />

      <Route path="/term-condition" element={<TermCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/forgot-password" element={<ForgotPswd />} />

      
    {  !auth &&   <Route path="*" element={<Navigate to="/login" replace />} />}


      


      
      {/* <Route path="/error" element={<Error />} /> */}
    </Routes>

  );
};

export default MainRoutes;
