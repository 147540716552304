import React from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Carsdata from '../common/Carsdata'
import Callus from '../common/Callus'
import QualityClients from '../common/QualityClients'
import Marquee from '../homeComponent/Marquee'

export default function About() {
  return (
    <div>
        <Navbar/>
<div className="no-bottom no-top zebra" id="content">
            <div id="top"></div>
            
            {/* <!-- section begin --> */}
            <section id="subheader" className="jarallax text-light">
                <img src="images/background/subheader.jpg" className="jarallax-img" alt=""/>
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
									<h1>About Us</h1>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </section>
            {/* <!-- section close --> */}

           <Carsdata/>

          <QualityClients/>

           <Callus/>
			
        </div>
         <Footer/>
    </div>
  )
}
