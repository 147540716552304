import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../common/LoadingSpinner";


export default function LoginForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});

  async function fetchHandler(url, data) {
    // setIsLoading(true);
    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setIsLoading(false);
        return result;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  // ----------------------------------------localization------------------------------
  // useEffect(() => {
  //   async function fetchData() {
  //     let url = `https://app.uberapps.tech/webservices/1.1.2/index.php/localisationapi/getlocalisations`;
  //     let data = "";

  //     const result = await fetchHandler(url, data);

  //     if (result?.code == 200) {
  //       console.log(result);
  //     }
  //   }

  //   // Call the async function
  //   setTimeout(() => {
  //     fetchData();
  //   }, [3000]);
  // }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("formData", formData);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let data = formData;
      let url = `${process.env.REACT_APP_URL}/userapi/login?`;
      async function fetchData() {
        setIsLoading(true);

        const result = await fetchHandler(url, data);

        if (result?.code == 200) {
          console.log(result, "result...............");
          var jsonResponse = {
            // user_id: result.response.api_key,
            password: result.response.u_password,
            name: result.response.u_name,
            phone: result.response.u_phone,
            fname: result.response.u_fname,
            lname: result.response.u_lname,
            userid: result.response.user_id,
            countryid: result.response.c_code,
            key: result.response.api_key,
            email: result.response.u_email,
            profile_path: result.response.u_profile_image_path,
          };
          var jsonString = JSON.stringify(jsonResponse);

          sessionStorage.setItem("authuser", jsonString);

          navigate("/dashboard");
          window.location.reload(true);
          setIsLoading(false);
        } else {
          console.log(result.message);
          toast.error("The email or password you entered is incorrect.");
        }
      }

      // Call the async function
      fetchData();
    }
    setValidated(true);
  };
  return (
    <>
      {isLoading && <LoadingSpinner />}
      <h4>Login</h4>

      <ToastContainer style={{ marginTop: "100px" }} />
      <div className="spacer-10"></div>
      <Form
        noValidate
        id="form_register"
        className="form-border"
        validated={validated}
        onSubmit={handleSubmit}
        // action="/userapi/login"
        method="post"
      >
        <Form.Group controlId="u_email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            name="u_email"
            placeholder="Your Email"
            required
            pattern="^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$"
            onChange={handleInputChange}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <div className="spacer-10"></div>

        <Form.Group controlId="u_password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="u_password"
            placeholder="Your Password"
            required
            // pattern="^[a-z])(?=.*[A-Z]$"
            onChange={handleInputChange}
          />
          <Form.Control.Feedback type="invalid" >
            Please provide a valid password.
          </Form.Control.Feedback>
        </Form.Group>
        {/* 
        <div className="spacer-10"></div>
        <div className="spacer-10"></div> */}
        <div className="text-right mb-1 mt-3 small link-Hover ">
          <Link to="/forgot-password" className="link-dark">
            Forgot Password?
          </Link>
        </div>

        <Button
          type="submit"
          id="send_message"
          className="btn-main btn-fullwidth rounded-3"
        >
          Login
        </Button>

        <div className="text-center mt-2 small font-weight-bold  link-Hover">
          Don't have an account?
          <Link to="/register" className="link-dark">
          {" "}Register now.
          </Link>
        </div>
      </Form>
    </>
  );
}

