import React from 'react'
import {Link} from "react-router-dom"

export default function Callus() {
  return (
    <div>
<section id="section-call-to-action" className="bg-color-2 pt60 pb60 text-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-1">
                            <span className='subtitle text-white'>
                            Call us for further information. 
                            </span>
                            <h2 className="s2">Rentaly customer care is here to help you anytime.</h2>
                        </div>

                        <div className="col-lg-5 text-lg-center text-sm-center">
                            <div className="phone-num-big">
                                <i className="fa fa-phone"></i>
                                <span className="pnb-text">
                                    Call Us Now
                                </span>
                                <span className="pnb-num">
                                +91-886-021-3347
                                </span>
                            </div>
                            <Link to="/contact" className="btn-main">
                        Contact us
                      </Link>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}
