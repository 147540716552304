import React ,{useEffect} from "react";
import Home from "../component/Home";
import Booking from "../component/Booking";
// import AcountDashboard from '../component/AcountDashboard'
import AccountBooking from "../component/AccountBooking";
import Contact from "../component/Contact";
import About from "../component/About";
import { Routes, Route, useLocation } from "react-router-dom";
import Profile from "../component/Profile";
import AcountDashboard from "../component/AcountDashboard";
import Error from "../common/Error";

import { Navigate, useRoutes } from "react-router-dom";
import RentalBooking from "../component/RentalBooking";
import TermCondition from "../component/TermCondition";
import PrivacyPolicy from "../component/PrivacyPolicy";
import { ForgotPswd } from "../component/ForgotPswd";

const LoginRoutes = () => {
  const auth = sessionStorage.getItem('authuser')


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/booking" element={<Booking />} />
      <Route path="/rental-booking" element={<RentalBooking />} />

      
      <Route path="/dashboard" element={<AcountDashboard />} />
      <Route path="/account-booking" element={<AccountBooking />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/account-profile" element={<Profile />} />
      <Route path="/term-condition" element={<TermCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/forgot-password" element={<ForgotPswd />} />

      {  auth &&   <Route path="*" element={<Navigate to="/dashboard" replace />} />}


      {/* <Route path="*" element={<Navigate to="/dashboard" replace />} /> */}


      {/* <Route path="/error" element={<Error />} /> */}
      {/* <Route path="*" element={<Navigate to="/error" replace />} /> */}
    </Routes>
  );
};

export default LoginRoutes;
