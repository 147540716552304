import React from 'react'
import { Link,useNavigate } from "react-router-dom";
import { OFFICE_ADDRESS } from "../utils/Constant";

function SocialMediaLink({ platform, username }) {
	const platforms = {
      facebook: {
			iconClass: 'fa fa-facebook fa-lg',
			baseUrl: '',
		  },
	  twitter: {
		iconClass: 'fa fa-twitter fa-lg',
		baseUrl: '',
	  },
	  instagram: {
		iconClass: 'fa fa-instagram fa-lg',
		baseUrl: '',
	  },
	//   whatsapp: {
	// 	iconClass: 'fab fa-whatsapp',
	// 	baseUrl: 'https://api.whatsapp.com/send?phone=',
	//   },
	linkedin: {
	  iconClass: 'fa fa-linkedin fa-lg',
	  baseUrl: '',	
	},
	pinterest: {
		iconClass: 'fa fa-pinterest fa-lg',
		baseUrl: '',	
	  },

	
	  // Add other platforms as needed
	};
  
	const platformInfo = platforms[platform];
  
	if (!platformInfo) {
	  return null; // Return null if the platform is not recognized
	}
  
	const url = platformInfo.baseUrl + username;
    return (
        <li>
          <a href={url} target="_blank" rel="noreferrer">
            <i className={platformInfo.iconClass}></i>
          </a>
        </li>
      );
}

export default function Footer() {
    const auth = sessionStorage.getItem('authuser')

    const phone = process.env.REACT_APP_PHONE;
	const website = process.env.REACT_APP_WEBURL;
	const mail = process.env.REACT_APP_EMAIL;
	const mailtoLink = `mailto:${mail}` ; 
	const telLink = `tel:${phone}` ; 
	const webLink = `https://${website}`;

	const socialMediaLinks = [
		{ platform: 'facebook', username: process.env.REACT_APP_FACEBOOK_URL },
		{ platform: 'twitter', username: process.env.REACT_APP_TWITTER_URL },
		{ platform: 'instagram', username: process.env.REACT_APP_INSTRAGRAM_URL },
		{ platform: 'pinterest', username: process.env.REACT_APP_PINTEREST_URL },
		{ platform: 'linkedin', username: process.env.REACT_APP_LINKED_IN_URL },
		
		// Add more social media platforms and usernames
	  ];
  return (

    <div> <a href="#" id="back-to-top"></a>
    <footer className="text-light">
            <div className="container">
                <div className="row g-custom-x">
                    <div className="col-lg-3">
                        <div className="widget">
                            <h5>About UberAPPS</h5>
                            <p>UBERApps - A fully customizable SAAS product, the best selling solution in the market.

We at UBERApps Technologies are continuously developing and improvising the OnDemand Services Experience, making it super easy and affordable for all Startups, Entrepreneurs, or business owners.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-5">
                        <div className="widget">
                            <h5>Contact Info</h5>
                            <address className="s1"                            >

                            <span><i className="id-color fa fa-map-marker fa-lg"></i><a target='_blank' href={`https://www.google.com/maps/place/Grepix+Infotech+Pvt.+Ltd./@28.581949,77.3161826,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce3df61eb1025:0xeccf0946db88b754!8m2!3d28.581949!4d77.3187575!16s%2Fg%2F11bvcv6g72?entry=ttu`}>{OFFICE_ADDRESS.INDIA.address}</a></span>
                            <span><i className="id-color fa fa-phone fa-lg"></i><a target='_blank' href={`tel:${OFFICE_ADDRESS.INDIA.whatsapp}`}>{OFFICE_ADDRESS.INDIA.phone}</a></span>
                            <span><i className="fa fa-whatsapp fa-lg"></i><a target='_blank' href={`tel:${OFFICE_ADDRESS.INDIA.whatsapp}`}>{OFFICE_ADDRESS.INDIA.whatsapp}</a></span>
                            <span><i className="id-color fa fa-envelope-o fa-lg"></i><a target='_blank' href={`mailto:${OFFICE_ADDRESS.INDIA.email}`}>{OFFICE_ADDRESS.INDIA.email}</a></span>


                                {/* <span ><i className="id-color fa fa-map-marker fa-lg "></i>{OFFICE_ADDRESS.INDIA.address}</span>
                                <span><i className="id-color fa fa-phone fa-lg"></i>{OFFICE_ADDRESS.INDIA.phone}</span>
                                <span><i className="fa fa-whatsapp fa-lg"></i> {OFFICE_ADDRESS.INDIA.whatsapp} </span>
                                <span><i className="id-color fa fa-envelope-o fa-lg"></i><a href="mailto:contact@example.com">{OFFICE_ADDRESS.INDIA.email}</a></span> */}
                                {/* <span><i className="id-color fa fa-file-pdf-o fa-lg"></i><a href="#">Download Brochure</a></span> */}
                            </address>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <h5>Quick Links</h5>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="widget">
                                    <ul>
                          
                      
                                        <li><Link to="/" className="menu-item"> Home</Link></li>
                                        <li><Link to="/about"  className="menu-item">  About Us</Link></li>
                                       { <li><Link to="/booking" className="menu-item"> Booking</Link></li>}
                                        <li><Link to="/contact"className="menu-item"> Contact</Link></li>
                                        {/* <li><a href="#">Partners</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="widget">
                            <h5>Social Network</h5>
                            <div className="social-icons">
                              

                                {/* < ul class="fsocial-links"> */}
                                {/* <ul> */}
                                                      
                          {/* <li><a href={process.env.REACT_APP_FACEBOOK_URL} className="menu-item" target='_blank'> <i className="id-color fa fa-facebook fa-lg "> </i></a></li>
                          <li><a href={process.env.REACT_APP_TWITTER_URL} className="menu-item" target='_blank'>  <i className="id-color fa fa-twitter fa-lg "> </i></a></li>
                          <li><a href={process.env.REACT_APP_LINKED_IN_URL} className="menu-item" target='_blank'> <i className="id-color fa fa-linkedin fa-lg "> </i></a></li>
                          <li><a href={process.env.REACT_APP_INSTRAGRAM_URL} className="menu-item"> <i className="id-color fa fa-instagram fa-lg " target='_blank'> </i></a></li> */}
                          
                      
                          <Link to="https://www.facebook.com/uberappstech" className="menu-item" target='_blank'> <i className="id-color fa fa-facebook fa-lg "> </i></Link>
                          <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FUBERAppsTe" className="menu-item" target='_blank'>  <i className="id-color fa fa-twitter fa-lg "> </i></Link>
                          <Link to="https://www.linkedin.com/company/uberapps-tech/" className="menu-item" target='_blank'> <i className="id-color fa fa-linkedin fa-lg "> </i></Link>
                          <Link to="https://www.instagram.com/uberapps.tech/" className="menu-item" target='_blank'> <i className="id-color fa fa-instagram fa-lg " > </i></Link>

                      {/* </ul> */}
                          {/* <li><a href="#">Partners</a></li> */}
                                {/* {socialMediaLinks.map((link) => (
											link.username && (
												<SocialMediaLink
												key={link.platform}
												platform={link.platform}
												username={link.username}
												/>
											)
											))} */}
                                {/* </ul> */}

                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col text-center">
                                    <a href="index.html">
                                    Copyright 2023 © UBERApps. All rights reserved.


                                    </a>
                                </div>
                                <ul className="menu-simple d-block text-center">
                                    <li><a href="/term-condition">Terms &amp; Conditions</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </div>
  )
}
