import React from 'react'
import Navbar from '../common/Navbar'
import ForgotPswdForm from '../forms/ForgotPswdForm'
import Footer from '../common/Footer'

export const ForgotPswd = () => {
  return (
    <div>

    <Navbar/>
    <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <section id="section-hero" aria-label="section" className="jarallax">
            <img src="images/background/2.jpg" 
            className="jarallax-img"
             alt=""/>
            <div className="v-center">
                <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 offset-lg-4">
                                <div className="padding40 rounded-3 shadow-soft" data-bgcolor="#ffffff" style={{background:"white"}}>
                                    <ForgotPswdForm/>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    </div>
    

<Footer/>
</div>
  )
}



