import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button, Col, FormGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { setHours, setMinutes, addMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import LoadingSpinner from "../common/LoadingSpinner";
import { useNavigate } from "react-router-dom";



export default function RentalBookingForm() {
  const navigate = useNavigate();
  const userInfoString = sessionStorage.getItem("authuser");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const apikey = userInfo?.key || null;
  const userId = userInfo?.userid || null;

  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let [formData, setFormData] = useState({});
  let [selectedVehicle, setSelectedVehicle] = useState(null);
  const [VehicleList, setVehicleList] = useState();

  const [address, setaddress] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const autocompleteRef = useRef(null);
  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });

  const defaultTime = new Date();
  defaultTime.setMinutes(defaultTime.getMinutes() + 30);
  const [selectedDate, setSelectedDate] = useState(defaultTime);
  const [GMTtime, setGMTtime] = useState(
    moment.utc(defaultTime).format("YYYY-MM-DD HH:mm:ss")
  );

  const [packages, setPackages] = useState();
  const [selectedPackage, setSelectedPackage] = useState();

  const [fareDetails, setFareDetails] = useState({
    baseFare: null,
    packName: null,
    serviceTax: null,
    totalFare: null,
    pack_cat_ID:null,
    pack_cat_name:null
  });



  // ----------------------------------------common fetch function ------------------------------
  function fetchHandler(url, data) {
    // setIsLoading(true);
    return fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      return res.json().then((result) => {
        // setIsLoading(false);
        return result;
      });
    });
  }


  // --------------------------------------fetch vehicle List------------------------------------------
  useEffect(() => {
    let url = `${process.env.REACT_APP_URL}/categoryapi/getcategories`;
    let data = { 
      api_key:apikey,
      city_id: process.env.REACT_APP_CITY_ID };

    async function fetchData() {
      const result = await fetchHandler(url, data);

      if (result?.code == 200) {
        const newData = [...result.response];
        newData.sort((a, b) => parseInt(a.sort_order) - parseInt(b.sort_order));
        setVehicleList(newData);
      }
      //  else {
      //   alert("Please refresh your page");
      // }
    }
    fetchData();
  }, []);

  // --------------------------------------fetch package List------------------------------------------
  useEffect(() => {
    let url = `${process.env.REACT_APP_URL}/tripapi/estimatepackagetripfare`;
    let data = {
      city_id: process.env.REACT_APP_CITY_ID,
      user_id: userId,
    };

    async function fetchData() {
      const result = await fetchHandler(url, data);

      if (result?.code == 200) {
        console.log(result, "pack..............");
        const newData = [...result.response];
        // newData.sort((a, b) => parseInt(a.sort_order) - parseInt(b.sort_order));
        setPackages(newData);
      }
      //  else {
      //   alert("Please refresh your page");
      // }
    }
    fetchData();
  }, []);

// ----------------------------------------------------Select Package----------------------------------
  const handlePackage = (val) => {
    setSelectedPackage(val);
    setFareDetails((prevDetails) => ({
      ...prevDetails,
      packName: val.name,
    }));
  };


  // --------------------------------------------------select vehicle function--------------------------------
  const handleVehicleType = (val) => {
    setSelectedVehicle(val);
    setFormData({
      ...formData,
      cat_name: val?.cat_name,
      category_id: val?.category_id,
      trip_base_fare: val?.cat_base_price,
    });
  };
  useEffect(() => {
    let defaultVehicle = VehicleList?.[0];
    handleVehicleType(defaultVehicle);
  }, [VehicleList]);

  // ---------------------------------------handle pickup Location------------------------------

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);

    console.log(l1, "li...........");
    setcoordinate(l1);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
  };

  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
  };


  // ----------------------------------------handle date Time----------------------------------------------
  const handleDateAndTime = (date) => {
    const gmtTime = moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
    setGMTtime(gmtTime);
    setSelectedDate(date);
  };


// ------------------------------------------------Booking Summary details-------------------------------------
useEffect(() => {
  selectedPackage?.Category?.map((val, i) => {
    if (val?.category_id === selectedVehicle?.category_id) {
      setFareDetails((prevDetails) => ({
        ...prevDetails,
        baseFare: val.est.trip_base_fare,
        serviceTax: val.est.tax_amt,
        totalFare: val.est.trip_pay_amount,
        pack_cat_ID: val.est.pkg_category.pkg_cat_id,
        pack_cat_name:val.est.pkg_category.name,
      }));
      console.log(val.est.pkg_category.pkg_cat_id,"val.est.pkg_category.pkg_cat_id...........");
    }
  });
}, [selectedPackage, selectedVehicle]);


// ----------------------------------------------Handle submit------------------------------------
  const handleSubmit = (event) => {
    console.log("formData", formData);
    if (!selectedPackage) {
      setValidated(false);
    }
    setValidated(true);


    event.preventDefault();

    async function fetchData() {
      let url = `${process.env.REACT_APP_URL}/tripapi/save`;
   

      let data = {
        api_key:apikey,
        trip_date: GMTtime,
        trip_from_loc: address,
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_pay_amount: fareDetails.totalFare,
        trip_distance: selectedPackage.distance,
        category_id: selectedVehicle?.category_id,
        tax_amt: fareDetails.serviceTax,
        trip_type: "rental",
        pkg_cat_id: fareDetails.pack_cat_ID,
        user_id: userId,
        trip_dunit: "km",
        seats: "1",
        trip_currency: process.env.REACT_APP_CURRENCY,
        pkg_cat_name: fareDetails.pack_cat_name,
        trip_comp_commision: "",
        is_ride_later: "1",
        is_share: "0",
        trip_status: "request",
        cat_name: selectedVehicle.name,
        trip_base_fare:fareDetails.baseFare ,
        api_key:apikey,
        city_id: process.env.REACT_APP_CITY_ID,
        trip_base_fare: formData.trip_base_fare,
        trip_total_time: selectedPackage.hours,
        
        
        
      };
      console.log(data, "data");
    
      const result = await fetchHandler(url, data);

      setIsLoading(false)
      if (result?.code == 200) {
        // alert("hii")
        toast.success("Your request has been saved successfully,");
        setaddress("");
        setAddressValue("");
        setPickupAddress(" ");
        setcoordinate((prevCoordinate) => ({
          ...prevCoordinate,
          lat: "",
          lng: "",
        }));
        handleDateAndTime(defaultTime);
        setSelectedPackage("")
      setValidated(false);

        console.log(result, "result.......");

        setTimeout(() => {
          navigate("/dashboard");
        }, 4000);
      } else {
        toast.error(result?.message);
      }
    }
    if (address && selectedPackage) {
      setIsLoading(true)
      fetchData();
    }

   
     
  };

  return (
    <>
         {isLoading && <LoadingSpinner/> }
      <ToastContainer />

      <Form
        name="contactForm"
        id="contact_form"
        // action="/userapi/bookinjg"
        // method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div id="step-1" className="row mb30">
          <div
            className="de-box col-lg-9 col-md-12 col-sm-3"
            style={{ background: "white", marginLeft: "-15px" }}
          >
            <div className="row w-100">
              <div className="col-lg-5 col-sm-3  col-md-5 mb30">
                {/* ------------------------------------------------Select vehicle type--------------------------------------------- */}
                <h5>What is your vehicle type? </h5>
                <div className="de_form de_radio g-0 "
                 style={{overflowX:"auto", display:"flex" , paddingBottom:"15px"}}
                >
                  {VehicleList?.map((val, i) => (
                    <div
                      key={i}
                      className="radio-img col-lg-3 col-sm-2 col-md-4"
                    >
                      <Form.Check
                        type="radio"
                        name="cat_name"
                        id={val.category_id}
                        value={val.cat_name}
                        label={
                          <div className="VehicleText">
                            <img src={val.cat_icon_path} alt="" s />
                            {val.cat_name}
                          </div>
                        }
                        onChange={(event) => {
                          handleVehicleType(val);
                        }}
                        defaultChecked={i === 0}
                      />
                    </div>
                  ))}
                </div>

                {/* ---------------------------------------- pickup Date & Time --------------------------------- */}

                <div className="col-lg-8 mt80">
                  <h5>Pick Up Date & Time</h5>
                  <div className="date-time-field">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateAndTime}
                      //    onChange={(e) => handleDateAndTime(e)}
                      // onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      timeIntervals={5}
                      minDate={new Date()}
                      minTime={
                        new Date(selectedDate).toDateString() ===
                        new Date().toDateString()
                          ? defaultTime
                          : null
                      }
                      maxTime={
                        new Date(selectedDate).toDateString() ===
                        new Date().toDateString()
                          ? new Date().setHours(23, 55)
                          : null
                      }
                      dateFormat="yyyy/mm/dd hh:mm aa"
                    />

                    <Form.Control.Feedback type="invalid">
                      Please select a time.
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div />

                {/* ----------------------------------------select locations --------------------------------- */}
              </div>

              <div className="col-lg-7">
                <div className="row">
                  {/* ----------------------------------------select pickup location --------------------------------- */}
                  <div className="col-lg-12 col-md-10 col-sm-9 mb20">
                    <Form.Group>
                      <h5>Pick Up Location</h5>

                      <PlacesAutocomplete
                        value={address}
                        onChange={onChangeAddress}
                        onSelect={handleSelect}
                        ref={autocompleteRef}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="autoCommpleteInput">
                            {/* <input {...getInputProps({ placeholder: 'Enter Your Pickup Location' })} style={{    width:"90%", border: "0px solid"}}/> */}

                            <Form.Control
                              {...getInputProps({
                                placeholder: "Enter Your Pickup Location",
                              })}
                              style={{ width: "100%", paddingRight: "20px" }}
                              required
                            />
                            {addressValue && (
                              <span
                                className="crossBtn"
                                onClick={handleClearSelection}
                              >
                                <b>X</b>
                              </span>
                            )}

                            <div>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  key={suggestion.id}
                                  {...getSuggestionItemProps(suggestion)}
                                  className="dropDownBorder"
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                            {/* <div> */}

                            <Form.Control.Feedback
                              type="invalid"
                              className="fs-6 "
                            >
                              Please enter a valid pickup location.
                            </Form.Control.Feedback>
                            {/* </div> */}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Group>
                  </div>

                  {/* ----------------------------------------select Packages --------------------------------- */}
                  <FormGroup>
                    <div className="col-lg-12 mb20">
                      <h5>Select Package</h5>
                      <div className="de_form de_radio row g-0 package">
                        <div
                          className="row "
                          style={{
                            overflowX: "auto !important",
                            overflowY: "hidden",
                            width: "100%",
                            //  height:"100px",
                            display: "flex !important",
                            flexDirection: "row !important",
                            // border:"2px solid black"
                          }}
                        >
                          {packages?.map((val, i) => (
                            // <div key={i} className="radio-img col-lg-3 col-sm-5 col-12 package pl0"
                            <div
                              key={i}
                              className="radio-img col-12 col-sm-12 package"
                              style={{
                                width: "120px",
                                marginLeft: "-40px",
                              }}
                            >
                              <Form.Check
                                required
                                type="radio"
                                name="pak"
                                id={val.name + i}
                                value={val.name}
                                label={
                                  <div className="VehicleText mt10 ">
                                    <b>{val.hours}hrs</b>
                                    {/* {val.name} */}

                                    <p className="">{val.distance} Km</p>
                                  </div>
                                }
                                onChange={(event) => {
                                  handlePackage(val);
                                }}
                                // defaultChecked={i === 0}
                                checked={selectedPackage === val}
                                // isInvalid={!selectedPackage}
                                feedbackType="invalid"
                                // feedback="You must agree before submitting."
                              />
                            </div>
                          ))}
                          {!selectedPackage && validated && (
                            <div className="fs-6 text-danger mt-10 ">
                              {" "}
                              Please select a package.
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <Form.Group>
          <h5>Select package</h5>
          <Form.Check
          type="checkbox"
          label={
            <div className="switch-with-title s2 col-sm-2 p0 bg-success text-white">
            <b>4hrs</b>
            <p className="mt-10">80 Km</p>
            </div>
          }
          id="packageCheckbox"
          checked={isChecked}
            onChange={handleCheckboxChange}
            isInvalid={!isChecked}
            />
            <Form.Control.Feedback type="invalid">Please select a package.</Form.Control.Feedback>
          </Form.Group> */}
                      {/* <Form.Group>
                      <h5>Select package</h5>
                      
                      
                      <div className="switch-with-title s2  col-sm-2 p0 bg-success text-white" >
                      <b>4hrs</b>
                      <p className="mt-10 " >
                      80 Km
                      </p>
                      
                      
                        </div>
                     

                      </Form.Group> */}
                    </div>
                  </FormGroup>
                  {/* ----------------------------------------select pickup location --------------------------------- */}
                  {/* <div className="col-lg-6 mb20">
                     <Form.Group>
                     <h5>Drop Off Location</h5>
                     <PlacesAutocomplete
                     value={dropoffAddress}
                     onChange={handleChangeDropoff}
                        onSelect={handleSelectDropoff}
                        // onSelect={handleDrop}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="autoCommpleteInput">
                            <Form.Control
                              {...getInputProps({
                                placeholder: "Enter Your Drop Off Location",
                              })}
                              style={{ width: "100%", paddingRight: "20px" }}
                              required
                            />
                            {dropoffAddressValue && (
                              <span
                                className="crossBtn"
                                onClick={handleClearSelectionDropoff}
                              >
                                <b>X</b>
                              </span>
                            )}
                            <div>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  key={suggestion.id}
                                  {...getSuggestionItemProps(suggestion)}
                                  className="dropDownBorder"
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid drop-off location.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Group> 

                    <div className="jls-address-preview jls-address-preview--hidden">
                      <div className="jls-address-preview__header"></div>
                    </div>
                  </div> */}

                  {/* ---------------------------------------- pickup Date & Time --------------------------------- */}
                  {/* <div className="col-lg-6 mb20">
                    <h5>Pick Up Date & Time</h5>
                    <div className="date-time-field">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateAndTime}
                        //    onChange={(e) => handleDateAndTime(e)}
                        // onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeIntervals={5}
                        minDate={new Date()}
                        minTime={
                          new Date(selectedDate).toDateString() ===
                          new Date().toDateString()
                            ? defaultTime
                            : null
                        }
                        maxTime={
                          new Date(selectedDate).toDateString() ===
                          new Date().toDateString()
                            ? new Date().setHours(23, 55)
                            : null
                        }
                        dateFormat="yyyy/mm/dd hh:mm aa"
                      />

                      <Form.Control.Feedback type="invalid">
                        Please select a time.
                      </Form.Control.Feedback>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-12">
                  <Button
                    type="submit"
                    id="send_message"
                    className="btn-main pull-right"
                  >
                    Book now
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* ---------------------------------------Booking summary------------------------------- */}

          <div
            className="col-md-11 de-box col-lg-3 col-sm-2 "
            style={{ background: "white", marginLeft: "15px" }}
          >

           
            <div className="mb10">
              <h4 className="text-center">Booking Summary</h4>
            {selectedPackage &&

              <div className="row">
                <div className="col-sm-5 col-lg-6 col-md-5 fs-6 text-start text-dark ">
                  <div>Package:</div>
                  <div>Base Fare:</div>
                  <div>Estimated Fare:</div>
                  <div>Taxes:</div>
                  <div>Total Fare:</div>
                </div>
                <div className="col-sm-7 col-lg-6 col-md-7  text-end  fs-6">
                  <div>{fareDetails.packName}</div>
                  <div>{process.env.REACT_APP_CURRENCY}{fareDetails.baseFare}</div>
                  <div>{process.env.REACT_APP_CURRENCY}{fareDetails.totalFare}</div>
                  <div>{process.env.REACT_APP_CURRENCY}{fareDetails.serviceTax || 0}</div>
                  <div>{process.env.REACT_APP_CURRENCY}{fareDetails.totalFare || 0}</div>
                </div>
              </div>
            }
            </div>
           
          </div>
        </div>
      </Form>
    </>
  );
}
