import React from 'react'

export default function Carsdata() {
  return (
    <div>


<section className="jarallax">
                {/* <img src="images/background/2.jpg" className="jarallax-img" alt=""/> */}
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInRight">
                            <h2>We offer customers a wide range of <span className="id-color">commercial cars</span> and <span className="id-color">luxury cars</span> for any occasion.</h2>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft">
                            Lorem ipsum non aliquip esse do eu ad amet laboris do labore reprehenderit mollit exercitation cillum irure fugiat magna laboris aliquip adipisicing consectetur officia dolor minim ea enim amet in ut non non excepteur anim magna dolor nostrud commodo qui irure deserunt adipisicing nisi ex nostrud sunt officia in aliquip velit anim id aliqua qui do sed non ad qui sed in eu in aliqua sunt pariatur occaecat in ullamco deserunt dolor consectetur laborum non duis occaecat nulla ut sed qui sunt id ex sint sed eu excepteur minim nulla minim excepteur exercitation.
                        </div>
                    </div>
                    <div className="spacer-double"></div>
                    <div className="row text-center">
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div className="de_count transparent text-light wow fadeInUp">
                                <h3 className="timer" data-to="15425" data-speed="3000">0</h3>
                                Hours of Work
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div className="de_count transparent text-light wow fadeInUp">
                                <h3 className="timer" data-to="8745" data-speed="3000">0</h3>
                                Clients Supported
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div className="de_count transparent text-light wow fadeInUp">
                                <h3 className="timer" data-to="235" data-speed="3000">0</h3>
                                Awards Winning
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div className="de_count transparent text-light wow fadeInUp">
                                <h3 className="timer" data-to="15" data-speed="3000">0</h3>
                                Years Experience
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
    </div>
  )
}
