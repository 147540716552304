import Marquee from "./Marquee";

import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { setHours, setMinutes, addMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import LoadingSpinner from "../common/LoadingSpinner";
import { useNavigate } from "react-router-dom";

export default function RentcarHeader() {
  const navigate = useNavigate();
  const userInfoString = sessionStorage.getItem("authuser");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const apikey = userInfo?.key || null;
  const userId = userInfo?.userid || null;

  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let [formData, setFormData] = useState({});
  let [selectedVehicle, setSelectedVehicle] = useState(null);
  const [VehicleList, setVehicleList] = useState();

  const [address, setaddress] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const autocompleteRef = useRef(null);
  const dropAutocompleteRef = useRef(null);
  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });
  const [coord, setcoord] = useState({
    lat: "",
    lng: "",
  });

  const [dropoffAddress, setDropoffAddress] = useState("");
  const [dropoffAddressValue, setDropoffAddressValue] = useState("");
  const [isDropoffAddressValid, setIsDropoffAddressValid] = useState(true);
  const [calculateDistance, setCalculateDistance] = useState("");
  const [calculateDuration, setCalculateDuration] = useState("");

  // const [selectedDate, setSelectedDate] = useState(new Date());
  // selectedDate.setMinutes(selectedDate.getMinutes() + 15);

  const defaultTime = new Date();
  console.log("defaultTime1", defaultTime);
  defaultTime.setMinutes(defaultTime.getMinutes() + 15);
  console.log("defaultTime2 + 15", defaultTime);

  const [selectedDate, setSelectedDate] = useState(defaultTime);
  console.log("selectedDate3", selectedDate);
  const [GMTtime, setGMTtime] = useState(
    moment.utc(defaultTime).format("YY-MM-DD HH:mm:ss")
  );
  console.log("GMTtime", GMTtime);

  const [estimPriceList, setEstimPriceList] = useState(null);
  const [baseFare, setBaseFare] = useState(null);
  const [farePerKm, setFarePerKm] = useState(null);
  const [farePerMin, setFarePerMin] = useState(null);
  const [serviceTax, setServiceTax] = useState(null);
  const [totalFare, setTotalFare] = useState(null);

  // ----------------------------------------common fetch function ------------------------------
  async function fetchHandler(url, data) {
    // setIsLoading(true);
    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // setIsLoading(false);
        return result;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  // --------------------------------------fetch vehicle List------------------------------------------
  useEffect(() => {
    let url = `${process.env.REACT_APP_URL}/categoryapi/getcategories`;
    let data = { city_id: process.env.REACT_APP_CITY_ID };
    // let url = `https://app.uberapps.tech/webservices/1.1.2/index.php/categoryapi/getcategories`
    // let data = "";

    async function fetchData() {
      const result = await fetchHandler(url, data);

      if (result?.code == 200) {
        const newData = [...result.response];
        newData.sort((a, b) => parseInt(a.sort_order) - parseInt(b.sort_order));
        setVehicleList(newData);
      }
      // else {
      //   toast.error("Please refresh your page")
      //   // alert("Please refresh your page");
      //   window.reload()
      // }
    }
    fetchData();
  }, []);

  // --------------------------------------------------select vehicle function--------------------------------
  const handleVehicleType = (val) => {
    setSelectedVehicle(val);

    setBaseFare(val?.cat_base_price);
    setFarePerKm(val?.cat_fare_per_km);
    setFarePerMin(val?.cat_fare_per_min);
    setFormData({
      ...formData,
      cat_name: val?.cat_name,
      category_id: val?.category_id,
      trip_base_fare: val?.cat_base_price,
    });
  };
  useEffect(() => {
    let defaultVehicle = VehicleList?.[0];
    handleVehicleType(defaultVehicle);
  }, [VehicleList]);

  // ---------------------------------------handle pickup Location------------------------------

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);

    console.log(l1, "li...........");
    setcoordinate(l1);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
  };

  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
    setTotalFare("");
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        autocompleteRef?.current &&
        !autocompleteRef?.current?.event?.target?.value &&
        !addressValue
      ) {
        setaddress("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [address, addressValue]);

  // ---------------------------------------fetch distance duration------------------------------------------
  useEffect(() => {
    async function fetchData() {
      if (address == dropoffAddress) {
        toast.error("Please select different dropoff location");
        return;
      }

      let url = `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`;
      let data = "";
      const result = await fetchHandler(url, data);

      if (result?.status == "OK") {
        // console.log(result?.routes[0].legs[0].distance.text, "result.2.......");
        setCalculateDistance(result?.routes[0]?.legs[0]?.distance.text);
        setCalculateDuration(result?.routes[0]?.legs[0]?.duration.text);
      }
    }
    if (address && dropoffAddress) {
      fetchData();
    }
  }, [coordinate, coord]);

  // --------------------------------------------fetch Estimate price-----------------------------------------
  useEffect(() => {
    async function fetchEstimatePrice() {
      let url = `${process.env.REACT_APP_URL}/tripapi/estimatetripfare`;
      let data = {
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_scheduled_drop_lat: coord.lat,
        trip_scheduled_drop_lng: coord.lng,
        trip_distance: calculateDistance,
        trip_hrs: calculateDuration,
        trip_from_loc: address,
        trip_to_loc: dropoffAddress,
        trip_date: GMTtime,
        api_key: apikey,
        city_id: process.env.REACT_APP_CITY_ID,
        user_id: userId,
        seats: 1,
        is_share: 1,
      };
      setIsLoading(true);

      const result = await fetchHandler(url, data);
      setIsLoading(false);

      if (result?.code == 200) {
        setEstimPriceList(result.response);
        console.log(result.response, "result.3.......");
      }
    }
    if (calculateDistance && calculateDuration) {
      fetchEstimatePrice();
    }
  }, [calculateDistance, calculateDuration]);

  useEffect(() => {
    if (estimPriceList) {
      let catId = selectedVehicle.category_id;
      setServiceTax(estimPriceList[catId]?.tax_amt);
      setTotalFare(estimPriceList[catId]?.trip_pay_amount);
    }
  }, [selectedVehicle, estimPriceList]);

  // --------------------------------------handle dropoff location--------------------------------------------
  const handleChangeDropoff = (newValue) => {
    setDropoffAddress(newValue);
    setIsDropoffAddressValid(newValue.trim() !== "");
  };

  const handleSelectDropoff = (address, placeId) => {
    setDropoffAddress(address);
    setDropoffAddressValue(address);
    setIsDropoffAddressValid(true);
    handleDrop(address);
  };

  const handleClearSelectionDropoff = () => {
    setDropoffAddress("");
    setDropoffAddressValue("");
    setIsDropoffAddressValid(true);
    setTotalFare("");
  };

  const handleDrop = async (value) => {
    const result = await geocodeByAddress(value);
    // setdroplatlng(result[0].formatted_address);
    const l2 = await getLatLng(result[0]);
    console.log(l2, "l2........");
    setcoord(l2);
    // setDropValue(result[0].formatted_address);
    // setdrop(value);
  };

  useEffect(() => {
    const handleDropOutsideClick = (event) => {
      if (
        dropAutocompleteRef?.current &&
        !dropAutocompleteRef?.current?.event?.target?.value &&
        !dropoffAddressValue
      ) {
        setDropoffAddress("");
      }
    };

    document.addEventListener("mousedown", handleDropOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleDropOutsideClick);
    };
  }, [dropoffAddress, dropoffAddressValue]);

  // ----------------------------------------handle date Time----------------------------------------------
  const handleDateAndTime = (date) => {
    // const gmtTime = date.toUTCString();
    // console.log(gmtTime, "gmtDate.......");
    // const formattedDate = moment(gmtTime).format("YYYY-MM-DD" + " " + "HH:mm:ss");
    // console.log(formattedDate,"formattedDate........");

    const gmtTime = moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
    setGMTtime(gmtTime);

    setSelectedDate(date);
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleSubmit = (event) => {
    console.log("formData", formData);
    if (!userId) {
      navigate("/login");
    } else {
      event.preventDefault();

      async function fetchData() {
        let url = `${process.env.REACT_APP_URL}/tripapi/save?api_key=${apikey}`;
        let data = {
          trip_date: GMTtime,
          // cat_name: formData.cat_name,
          trip_from_loc: address,
          trip_to_loc: dropoffAddress,
          city_id: process.env.REACT_APP_CITY_ID,
          user_id: userId,
          category_id: formData.category_id,
          trip_currency: process.env.REACT_APP_CURRENCY,
          trip_base_fare: formData.trip_base_fare,
          trip_scheduled_pick_lat: coordinate.lat,
          trip_scheduled_pick_lng: coordinate.lng,
          trip_scheduled_drop_lat: coord.lat,
          trip_scheduled_drop_lng: coord.lng,
          trip_distance: calculateDistance,
          trip_total_time: calculateDuration,
          trip_pay_amount: totalFare,
          tax_amt: serviceTax,
          trip_dunit: "km",
          // trip_type: "normal",
          seats: "1",
          trip_status: "request",
          is_share: "0",
          is_delivery: "0",
        };
        console.log(data, "data");
        const result = await fetchHandler(url, data);
        setIsLoading(false);

        if (result?.code == 200) {
          // alert("hii")
          toast.success("Your request has been saved successfully,");
          setaddress(" ");
          setAddressValue("");
          setDropoffAddress(" ");
          setDropoffAddressValue("");
          handleDateAndTime(defaultTime);
          setTotalFare("");

          console.log(result, "result.......");
          setTimeout(() => {
            navigate("/dashboard");
          }, 4000);
        } else {
          toast.error(result?.message);
        }
      }
      if (address && dropoffAddress && totalFare) {
        setIsLoading(true);

        fetchData();
      }

      // const form = event.currentTarget;
      // if (form.checkValidity() === false) {
      //   event.stopPropagation();
      // }

      setValidated(true);
    }
  };

  return (
    <div>
      {/* 
<section id="section-hero" aria-label="section" 
className="jarallax no-top no-bottom" 
data-video-src="https://www.youtube.com/watch?v=5qbjKpxfD64"

> */}
      <section id="section-hero" aria-label="section" className="jarallax">
        <img src="images/background/2.jpg" className="jarallax-img" alt="" />
        <ToastContainer className="mt90 z-3" />

        {isLoading && <LoadingSpinner />}
        <div className="overlay-bg no-top no-bottom">
          <div className="v-center">
            <div className="container position-relative z1000">
              <div className="row align-items-center">
                <div className="col-lg-6 text-light">
                  <h4 className="mt-3">
                    <span className="id-color ">
                      Fast and Easy Way to Rent a Car
                    </span>
                  </h4>
                  <div className="spacer-10"></div>
                  <h1 className="mb-2">
                    Explore the world with comfortable car
                  </h1>
                  <div className="spacer-10"></div>
                  <p className="lead">
                    Eiusmod sunt occaecat pariatur ullamco eu cupidatat elit
                    aliqua ut duis minim sed nulla est mollit in proident dolor
                    id commodo officia consectetur nisi.
                  </p>
                </div>

                <div className="col-lg-6 ">
                  <div className="spacer-single sm-hide "></div>
                  <div
                    className="p-4 rounded-3 shadow-soft text-light  bg-dark border border-success"
                    data-bgcolor="rgba(0, 0, 0, .6)"
                  >
                    {/* <form name="contactForm" id='contact_form' method="post"> */}

                    <Form
                      name="contactForm"
                      id="contact_form"
                      // action="/userapi/bookinjg"
                      // method="post"
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <h5>What is your vehicle type?</h5>
                      <div
                        className="de_form de_radio  g-3"
                        style={{
                          // overflowX: "auto",
                          // display: "flex",
                          paddingBottom: "15px",
                          paddingLeft: "-100px",
                        }}
                      >
                        {VehicleList?.map((val, i) => (
                          <div
                            key={i}
                            className="radio-img col-lg-3 col-sm-3 col-6"
                          >
                            <Form.Check
                              type="radio"
                              name="cat_name"
                              id={val.category_id}
                              value={val.cat_name}
                              label={
                                <div className="HomeVehicleText small ">
                                  <img src={val.cat_icon_path} alt="" s />
                                  {val.cat_name}
                                </div>
                              }
                              onChange={(event) => {
                                handleVehicleType(val);
                              }}
                              defaultChecked={i === 0}
                            />
                          </div>
                        ))}
                        {/* <div className="radio-img col-lg-3 col-sm-3 col-6">
                                                    <input id="radio-1a" name="Car_Type" type="radio" 
                                                    // value="Residential" 
                                                    defaultChecked="checked"/>
                                                    <label htmlFor="radio-1a"><img src="images/select-form/car.png" alt=""/>Car</label>
                                                </div> */}

                        {/* <div className="radio-img col-lg-3 col-sm-3 col-6">
                                                    <input id="radio-1b" name="Car_Type" type="radio" 
                                                    // value="Office"
                                                    />
                                                    <label htmlFor="radio-1b"><img src="images/select-form/van.png" alt=""/>Van</label>
                                                </div>

                                                <div className="radio-img col-lg-3 col-sm-3 col-6">
                                                    <input id="radio-1c" name="Car_Type" type="radio" 
                                                    // value="Commercial"
                                                    />
                                                    <label htmlFor="radio-1c"><img src="images/select-form/minibus.png" alt=""/>Minibus</label>
                                                </div>

                                                <div className="radio-img col-lg-3 col-sm-3 col-6">
                                                    <input id="radio-1d" name="Car_Type" type="radio" 
                                                    // value="Retail"
                                                    />
                                                    <label htmlFor="radio-1d"><img src="images/select-form/sportscar.png" alt=""/>Prestige</label>
                                                </div> */}
                      </div>
                      {/* <div className="spacer-20"></div> */}
                      <div className="row">
                        <div className="col-lg-6 mb20">
                          <Form.Group>
                            <h5>Pick Up Location</h5>

                            <PlacesAutocomplete
                              value={address}
                              onChange={onChangeAddress}
                              onSelect={handleSelect}
                              ref={autocompleteRef}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="autoCommpleteInput">
                                  {/* <input {...getInputProps({ placeholder: 'Enter Your Pickup Location' })} style={{    width:"90%", border: "0px solid"}}/> */}

                                  <Form.Control
                                    {...getInputProps({
                                      placeholder: "Enter Your Pickup Location",
                                    })}
                                    style={{
                                      width: "100%",
                                      paddingRight: "25px",
                                    }}
                                    required
                                    // onBlur={()=>{setaddress(" ")}}
                                  />
                                  {addressValue && (
                                    <span
                                      className="crossBtn"
                                      onClick={handleClearSelection}
                                    >
                                      <b>X</b>
                                    </span>
                                  )}

                                  <div className="autocomplete-dropdown-container dropdown-width">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => (
                                      <div
                                        key={suggestion.id}
                                        {...getSuggestionItemProps(suggestion)}
                                        className="dropDownBorder"
                                      >
                                        {suggestion.description}
                                      </div>
                                    ))}
                                  </div>
                                  {/* <div> */}

                                  <Form.Control.Feedback type="invalid">
                                    Please enter a valid pickup location.
                                  </Form.Control.Feedback>
                                  {/* </div> */}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </Form.Group>
                          {/* <h5>Pick Up Location</h5>
                                                    <input type="text" name="PickupLocation" 
                                                    // onFocus="geolocate()" 
                                                    placeholder="Enter your pickup location" id="autocomplete" autoComplete="off" className="form-control"/> */}

                          <div className="jls-address-preview jls-address-preview--hidden">
                            <div className="jls-address-preview__header"></div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb20">
                          <Form.Group>
                            <h5>Drop Off Location</h5>
                            <PlacesAutocomplete
                              value={dropoffAddress}
                              onChange={handleChangeDropoff}
                              onSelect={handleSelectDropoff}
                              ref={dropAutocompleteRef}

                              // onSelect={handleDrop}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="autoCommpleteInput">
                                  <Form.Control
                                    {...getInputProps({
                                      placeholder:
                                        "Enter Your Drop Off Location",
                                    })}
                                    style={{
                                      width: "100%",
                                      paddingRight: "25px",
                                    }}
                                    required
                                    //  onBlur={()=>{setDropoffAddress(" ")}}
                                  />
                                  {dropoffAddressValue && (
                                    <span
                                      className="crossBtn"
                                      onClick={handleClearSelectionDropoff}
                                    >
                                      <b>X</b>
                                    </span>
                                  )}
                                  <div className="autocomplete-dropdown-container drop-dropdown-container dropdown-width">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => (
                                      <div
                                        key={suggestion.id}
                                        {...getSuggestionItemProps(suggestion)}
                                        className="dropDownBorder"
                                      >
                                        {suggestion.description}
                                      </div>
                                    ))}
                                  </div>
                                  <Form.Control.Feedback type="invalid">
                                    Please enter a valid drop-off location.
                                  </Form.Control.Feedback>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </Form.Group>
                          {/* <h5>Drop Off Location</h5>
                                                    <input type="text" name="DropoffLocation" 
                                                    // onFocus="geolocate()" 
                                                    placeholder="Enter your dropoff location" id="autocomplete2" autoComplete="off" className="form-control"/> */}

                          <div className="jls-address-preview jls-address-preview--hidden">
                            <div className="jls-address-preview__header"></div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12 ">
                          <div className="date-time-field homeDatePicker">
                            <h5>Pick Up Date & Time</h5>
                            <div>
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateAndTime}
                                //    onChange={(e) => handleDateAndTime(e)}
                                // onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                timeIntervals={5}
                                minDate={new Date()}
                                minTime={
                                  new Date(selectedDate).toDateString() ===
                                  new Date().toDateString()
                                    ? defaultTime
                                    : null
                                }
                                maxTime={
                                  new Date(selectedDate).toDateString() ===
                                  new Date().toDateString()
                                    ? new Date().setHours(23, 55)
                                    : null
                                }
                                dateFormat="yyyy/MM/dd hh:mm aa"
                              />
                            </div>

                            <Form.Control.Feedback type="invalid">
                              Please select a time.
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        <div className="col-lg-6  mt-3">
                          <div></div>
                          <h3 className="mt-6"></h3>
                          {address && dropoffAddress && totalFare && (
                            <div className="col-md-6 col-sm-6  col-lg-12 mb20">
                              <div></div>

                              <div
                                className="fw-bold transparent text-dark  text-center"
                                style={{ background: "var(--primary-color)" }}
                              >
                                Estimated Price :{" "}
                                <span className="text-light">
                                  {process.env.REACT_APP_CURRENCY}
                                  {totalFare}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Button
                        type="submit"
                        id="send_message"
                        className="btn-main pull-right mb-4"
                      >
                        Book now
                      </Button>{" "}
                      <div className="clearfix"></div>
                      {/* </form> */}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
         
            <div className="home__marquee">
            <Marquee />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
