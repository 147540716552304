import React, {useEffect} from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Carsdata from '../common/Carsdata'
import Callus from '../common/Callus'
import QualityClients from '../common/QualityClients'
import OurFeatures from '../homeComponent/OurFeatures'
import AnyQuestions from '../homeComponent/AnyQuestions'
import RentcarHeader from '../homeComponent/RentcarHeader'

export default function Home() {
 
  return (
    <div >
    <div id="wrapper">
        
        {/* <!-- page preloader begin --> */}
        {/* <div id="de-preloader"></div> */}
        {/* <!-- page preloader close --> */}
        
         <Navbar/>
       
        <div className="no-bottom no-top" id="content">
            <div id="top"></div>
            
            <RentcarHeader/>

{/* Common Component  */}
            <OurFeatures/>      
{/* Common Component  End*/}

            <Carsdata/>

            <AnyQuestions/>

{/* Common Component  */}

            <QualityClients/>
            
            <Callus/>

{/* Common Component End  */}
            
        </div>
        

     <Footer/>

    </div>
    
  

</div>
  )
}
