import React, { useState, useEffect } from "react";

export default function Marquee() {
  console.log("123");
  const [VehicleList, setVehicleList] = useState();

  async function fetchHandler(url, data) {
    // setIsLoading(true);
    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // setIsLoading(false);
        return result;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  useEffect(() => {
    let url = `${process.env.REACT_APP_URL}/categoryapi/getcategories`;
    let data = {
      city_id: process.env.REACT_APP_CITY_ID,
    };

    async function fetchData() {
      const result = await fetchHandler(url, data);

      if (result?.code == 200) {
        const newData = [...result.response];
        newData.sort((a, b) => parseInt(a.sort_order) - parseInt(b.sort_order));
        setVehicleList(newData);
        console.log("hiiii", newData);
      }
      //  else {
      //   alert("Please refresh your page");
      // }
    }
    fetchData();
  }, []);

  return (
    <>
      <div class="marquee__text__container">
        <div class="loop__text d-marquee-small">
          {VehicleList?.map((item, id) => (
            <>
              <span className="d-item-txt" key={id}>
                {item.cat_name}
              </span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
            </>
          ))}
          </div>
          <div class="loop__text d-marquee-small">
          {VehicleList?.map((item, id) => (
            <>
              <span className="d-item-txt" key={id}>
                {item.cat_name}
              </span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
            </>
          ))}
          </div>
          <div class="loop__text d-marquee-small">
          {VehicleList?.map((item, id) => (
            <>
              <span className="d-item-txt" key={id}>
                {item.cat_name}
              </span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
            </>
          ))}
          </div>
          <div class="loop__text d-marquee-small">
          {VehicleList?.map((item, id) => (
            <>
              <span className="d-item-txt" key={id}>
                {item.cat_name}
              </span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
            </>
          ))}
          </div>
      </div>
    </>
  );
}
