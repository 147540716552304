import React,{useState} from "react";
import { Link,useNavigate } from "react-router-dom";





function SocialMediaLink({ platform, username }) {
  const platforms = {
    facebook: {
      iconClass: "fa fa-facebook fa-lg",
      baseUrl: "",
    },
    twitter: {
      iconClass: "fa fa-twitter fa-lg",
      baseUrl: "",
    },
    instagram: {
      iconClass: "fa fa-instagram fa-lg",
      baseUrl: "",
    },
    //   whatsapp: {
    // 	iconClass: 'fab fa-whatsapp',
    // 	baseUrl: 'https://api.whatsapp.com/send?phone=',
    //   },
    linkedin: {
      iconClass: "fa fa-linkedin fa-lg",
      baseUrl: "",
    },
    pinterest: {
      iconClass: "fa fa-pinterest fa-lg",
      baseUrl: "",
    },

    // Add other platforms as needed
  };

  const platformInfo = platforms[platform];

  if (!platformInfo) {
    return null; // Return null if the platform is not recognized
  }

  const url = platformInfo.baseUrl + username;
  return (
    <li>
      <a href={url} target="_blank" rel="noreferrer">
        <i className={platformInfo.iconClass}></i>
      </a>
    </li>
  );
}

export default function Navbar() {
  const navigate = useNavigate();
  const auth = sessionStorage.getItem("authuser");
  const isLoggedIn = auth;

  const phone = process.env.REACT_APP_PHONE;
  const website = process.env.REACT_APP_WEBURL;
  const mail = process.env.REACT_APP_EMAIL;
  const mailtoLink = `mailto:${mail}`;
  const telLink = `tel:${phone}`;
  const webLink = `https://${website}`;

  const socialMediaLinks = [
    { platform: "facebook", username: process.env.REACT_APP_FACEBOOK_URL },
    { platform: "twitter", username: process.env.REACT_APP_TWITTER_URL },
    { platform: "instagram", username: process.env.REACT_APP_INSTRAGRAM_URL },
    { platform: "pinterest", username: process.env.REACT_APP_PINTEREST_URL },
    { platform: "linkedin", username: process.env.REACT_APP_LINKED_IN_URL },

    // Add more social media platforms and usernames
  ];

    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  const handleLogout = () => {
    sessionStorage.clear();
  };

  return (
    <header className="transparent scroll-light has-topbar">
      <div id="topbar" className="topbar-dark text-light">
        <div className="container .menu-Container  ">
          <div className="topbar-left xs-hide">
            <div className="topbar-widget">
              <div className="topbar-widget">
                <a href="tel:+91-8860213347">
                  <i className="fa fa-phone"></i>
                  {process.env.REACT_APP_PHONE}
                </a>
              </div>
              <div className="topbar-widget">
                <a href="/contact">
                  <i className="fa fa-envelope"></i>
                  {process.env.REACT_APP_EMAIL}
                </a>
              </div>
              <div className="topbar-widget">
                <a href={"/account-booking"}>
                  <i className="fa fa-clock-o"></i>
            
                </a>
              </div>
            </div>
          </div>

          <div className="topbar-right">
            <div className="social-icons">
              <ul className="fsocial-links">
                {socialMediaLinks.map(
                  (link) =>
                    link.username && (
                      <SocialMediaLink
                        key={link.platform}
                        platform={link.platform}
                        username={link.username}
                      />
                    )
                )}
              </ul>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      
      <div className={`container ${isMenuOpen && 'menuwrapper'}`}  >
        <div className="row" >
          <div className="col-md-12">
            <div className="de-flex sm-pt10">
              <div className="de-flex-col">
                <div className="de-flex-col">
                  {/* <!-- logo begin --> */}
                  <div id="logo">
                    <a href="/">
                      <img
                        className="logo-1  "
                        // src="images/uberAppLogolight.png"
                        src="images/uberappsHeaderLogo.png"

                        alt=""
                      />
                      {/* <img className="logo-2" src="images/logo.png" alt="" /> */}
                      <img className="logo-2   "
                        src="images/uberapps-logo.png"

                      //  src="images/logoDark.png"
                        alt="" />

                    </a>
                  </div>
                  {/* <!-- logo close --> */}
                </div>
              </div>
              <div className="de-flex-col header-col-mid ">
                {isLoggedIn ? (
                  <ul id="mainmenu">
                    <li>
                    <Link to="/" className="menu-item">
                       Home
                      </Link>
                      {/* <a className="menu-item" href="/">
                        Home
                      </a> */}
                    </li>

                    <li>
                    {/* <Link to="/booking" className="menu-item">
                    Booking
                      </Link> */}
                      {/* <a className="menu-item" href="/booking">
                        Booking
                      </a> */}


                     <Link  className="menu-item" to="/booking" >
                    Booking
                      </Link>
              
                      {/* <ul>
                        <li>

                        <Link to="/booking" className="menu-item"
           
                        >
                        One Way Booking
                      </Link>
                
                        </li>
                        <li>

                        <Link to="/rental-booking" className="menu-item">
                       By Hour Booking
                      </Link>
                 
                        </li>
                    
                      </ul> */}
                    </li>
                    <li>
                    <Link  className="menu-item">
                    My Account
                      </Link>
                      {/* <a className="menu-item" >
                        My Account
                      </a> */}
                      <ul>
                        <li>

                        <Link to="/dashboard" className="menu-item">
                        Dashboard
                      </Link>
                          {/* <a className="menu-item" href="/dashboard">
                            Dashboard
                          </a> */}
                        </li>
                        <li>

                        <Link to="/account-profile" className="menu-item">
                        My Profile
                      </Link>
                          {/* <a className="menu-item" href="/account-profile">
                            My Profile
                          </a> */}
                        </li>
                        <li>

                        <Link to="/account-booking" className="menu-item">
                        My Trips
                      </Link>
                          {/* <a className="menu-item" href="account-booking">
                            My Orders
                          </a> */}
                        </li>
                      </ul>
                    </li>
                    {/* <li> */}
                    {/* <Link to="#" className="menu-item">
                    Pages
                      </Link> */}
                   
                      {/* <ul> */}
                        <li>
                        <Link to="/about" className="menu-item">
                        About Us
                      </Link>
                          {/* <a className="menu-item" href="/about">
                            About Us
                          </a> */}
                        </li>
                        <li>
                        <Link to="/contact" className="menu-item">
                        Contact
                      </Link>
                          {/* <a className="menu-item" href="/contact">
                            Contact
                          </a> */}
                        </li>
                      </ul>
                    // </li>
                  // </ul>
                ) : (
                  <ul id="mainmenu">
                    <li>
                    <Link to="/" className="menu-item">
                       Home
                      </Link>
                      {/* <a className="menu-item" href="/">
                        Home
                      </a> */}
                    </li>

                    {/* <li> */}
                    {/* <Link to="#" className="menu-item">
                    Pages
                      </Link> */}
                      
                      {/* <a className="menu-item" href="#">
                        Pages
                      </a> */}
                      {/* <ul> */}
                        <li>
                        <Link to="/about" className="menu-item">
                        About Us
                      </Link>
                          {/* <a className="menu-item" href="/about">
                            About Us
                          </a> */}
                        </li>
                        <li>
                        <Link to="/contact" className="menu-item">
                        Contact
                      </Link>
                          {/* <a className="menu-item" href="/contact">
                            Contact
                          </a> */}
                        </li>
                     
                        <li>
                        <Link to="/register" className="menu-item">
                        Register
                      </Link>
                          {/* <a className="menu-item" href="/register">
                            Register
                          </a> */}
                        </li>
                      {/* </ul>
                    </li> */}
                  </ul>
                )}

                {/* <ul id="mainmenu">
                  <li>
                    <a className="menu-item" href="/">
                      Home
                    </a>
                  </li>

                  <li>
                    <a className="menu-item" href="/booking">
                      Booking
                    </a>
                  </li>
                  <li>
                    <a className="menu-item" href="/cars">
                      My Account
                    </a>
                    <ul>
                      <li>
                        <a className="menu-item" href="/dashboard">
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/account-profile">
                          My Profile
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="account-booking">
                          My Orders
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="menu-item" href="#">
                      Pages
                    </a>
                    <ul>
                      <li>
                        <a className="menu-item" href="/about">
                          About Us
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/contact">
                          Contact
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/login">
                          Login
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/register">
                          Register
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/error">
                          Page 404
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul> */}
              </div>
              <div className="de-flex-col">
                <div className="menu_side_area">
                  {isLoggedIn ? (
                    <>
                      <a
                        href="/login"
                        onClick={handleLogout}
                        className="btn-main"
                      > 
                        Logout
                      </a>
                    </>
                  ) : (
                    <>
                      <Link to="/login" className="btn-main">
                        Login
                      </Link>
                    </>
                  )}



      
                  <span id="menu-btn" onClick={toggleMenu}></span>
                </div>
              </div>
            </div>
          </div>
        </div>


                  {isMenuOpen && (
                    
        <div id="menu-list">
          <ul id="mainmenu col-12">
          {isLoggedIn ? (
                  <>
                    <li>
                    <Link to="/" className="menu-item">
                       Home
                      </Link>
                      {/* <a className="menu-item" href="/">
                        Home
                      </a> */}
                    </li>

                    <li>
                    {/* <Link to="/booking" className="menu-item">
                    Booking
                      </Link> */}
                      {/* <a className="menu-item" href="/booking">
                        Booking
                      </a> */}


                     <Link   to="/booking" >
                    Booking
                      </Link>
              
                      {/* <ul>
                        <li>

                        <Link to="/booking" className="menu-item"
           
                        >
                        One Way Booking
                      </Link>
                
                        </li>
                        <li>

                        <Link to="/rental-booking" className="menu-item">
                       By Hour Booking
                      </Link>
                 
                        </li>
                    
                      </ul> */}
                    </li>
                        <li>

                        <Link to="/account-profile" className="menu-item">
                        My Profile
                      </Link>
                          {/* <a className="menu-item" href="/account-profile">
                            My Profile
                          </a> */}
                        </li>
                    <li>
      
                 
                      
                                              <Link to="/dashboard" className="menu-item">
                                              Dashboard
                                            </Link>
                    
                    </li>
                        <li>

                        <Link to="/account-booking" className="menu-item">
                        My Trips
                      </Link>
                          {/* <a className="menu-item" href="account-booking">
                            My Orders
                          </a> */}
                        </li>
                        <li>
                        <Link to="/about" className="menu-item">
                        About Us
                      </Link>
                          {/* <a className="menu-item" href="/about">
                            About Us
                          </a> */}
                        </li>
                        <li>
                        <Link to="/contact" className="menu-item">
                        Contact
                      </Link>
                          {/* <a className="menu-item" href="/contact">
                            Contact
                          </a> */}
                        </li>
                         <a
                        href="/login"
                        onClick={handleLogout}
                        className="btn-main mt-4 logout-btn"
                      > 
                        Logout
                      </a>
          
                  </>
                ) : (
                  <>
               
                    <li>
                    <Link to="/" >
                       Home
                      </Link>
                      {/* <a className="menu-item" href="/">
                        Home
                      </a> */}
                    </li>

                
                      
                      {/* <a className="menu-item" href="#">
                        Pages
                      </a> */}
                      <ul>
                        <li>
                        <Link to="/about" className="menu-item">
                        About Us
                      </Link>
                          {/* <a className="menu-item" href="/about">
                            About Us
                          </a> */}
                        </li>
                        <li>
                        <Link to="/contact" className="menu-item">
                        Contact
                      </Link>
                          {/* <a className="menu-item" href="/contact">
                            Contact
                          </a> */}
                        </li>
                      
                        <li>
                        <Link to="/register" className="menu-item">
                        Register
                      </Link>
                          {/* <a className="menu-item" href="/register">
                            Register
                          </a> */}
                        </li>
                        <li><Link to="/login" className="menu-item">
                        Login
                      </Link></li>
                      </ul>
                  </>

                )}
            
     

          </ul>
        </div>
      )}
                  
      </div>
    </header>
  );
}

