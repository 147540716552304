import React from "react";


export default function LoadingSpinner() {
  return (
    // <div className="spinner-container">
    //   <div className="loading-spinner"></div>
    // </div>


<div className="overlay">
<div className="overlay__wrapper">
    <div class="overlay__spinner"></div>
</div>
</div>
  );
}