import React from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Carsdata from '../common/Carsdata'
import Marquee from '../homeComponent/Marquee'
import BookingForm from '../forms/BookingForm'

export default function Booking() {
  return (
    <div>
<Navbar/>


<div className="no-bottom no-top zebra" id="content">
            <div id="top"></div>
            
            {/* <!-- section begin --> */}
            <section id="subheader" className="jarallax text-light">
                <img src="images/background/subheader.jpg" className="jarallax-img" alt=""/>
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
									<h1>Booking</h1>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </section>
            {/* <!-- section close --> */}

            <section id="section-hero" aria-label="section" className="no-top mt-80 sm-mt-0">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-12">
                            <div className="spacer-single sm-hide"></div>

                            {/* <div className="p-4 rounded-3 shadow-soft" data-bgcolor="#ffffff" style={{background:"white"}}> */}
                                <BookingForm /> 
                            {/* </div> */}
                            
                        </div>
                    </div>

                    <div className="spacer-single"></div>

                    <div className="row">

                        <div className="col-md-3 wow fadeInRight" data-wow-delay=".2s" >
                            <div className="feature-box style-4 text-center">
                                <a href="#"><i className="bg-color text-light i-boxed fa fa-car"></i></a>
                                <div className="text">
                                    <a href="#"><h4>Choose a vehicle</h4></a>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                                </div>
                                <span className="wm">1</span>
                            </div>
                        </div>

                        <div className="col-md-3 wow fadeInRight" data-wow-delay=".4s" >
                            <div className="feature-box style-4 text-center">
                                <a href="#"><i className="bg-color text-light i-boxed fa fa-calendar"></i></a>
                                <div className="text">
                                    <a href="#"><h4>Pick location &amp; date</h4></a>
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla


                                </div>
                                <span className="wm">2</span>
                            </div>
                        </div>

                        <div className="col-md-3 wow fadeInRight" data-wow-delay=".6s" >
                            <div className="feature-box style-4 text-center">
                                <a href="#"><i className="bg-color text-light i-boxed fa fa-pencil-square-o"></i></a>
                                <div className="text">
                                    <a href="#"><h4>Make a booking</h4></a>
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                </div>
                                <span className="wm">3</span>
                            </div>
                        </div>

                        <div className="col-md-3 wow fadeInRight" data-wow-delay=".6s" >
                            <div className="feature-box style-4 text-center">
                                <a href="#"><i className="bg-color text-light i-boxed fa fa-smile-o"></i></a>
                                <div className="text">
                                    <a href="#"><h4>Sit back & relax</h4></a>
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo


                                </div>
                                <span className="wm">3</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


{/* We offers */}
            <Carsdata/>
{/* -- */}
            <div className="booking__marquee">
                <Marquee/>
            </div>

			
        </div>

        <Footer/>

    </div>
  )
}
